.ViewOwnProfileModels__container {
  width: 100%;
}

.profile_header {
  width: 100%;
  position: fixed;
  top: 0px !important;
  background: #f7f7f7;
  z-index: 9;
  width: 100%;
}

.ViewOwnProfileModels__IconButton {
  position: absolute !important;
  right: 23px;
}

.profile_header > p {
  color: #636363;
  text-shadow: none;
  font-size: 20px;
  font-weight: 600;
}

.ViewOwnProfileModelsTop__picture {
  display: flex;
  align-items: center;
  width: 96%;
  max-width: 700px;
  margin: 0 auto !important;
  padding: 0 8px;
}

.best-icon {
  position: absolute;
  width: 36px;
  left: 117px;
  top: 82px;
}

.ViewOwnProfileModelsTop__picture > img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-right: 24px;
}

.ViewOwnProfileModels__content {
  width: 100%;
}

.ViewOwnProfileModelsContent__top {
  position: relative;
  top: 0 !important;
  width: 100%;
  height: 78vh;
}

.ViewOwnProfileModelsTop__text {
  max-width: 400px;
}

.ViewOwnProfileContentBloc__text {
  display: flex;
  align-items: center;
}

.ViewOwnProfileContentBloc__text > h4 {
  font-size: 23px !important;
  /* margin-bottom: 5px; */
  font-weight: 300;
  margin-right: 10px;
}

.ViewOwnProfileModelsTop__text > sub {
  color: #636363;
}

.ViewOwnProfileModelsBloc__numbers,
.ViewOwnProfileModelsBloc__text {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 133%;
  margin-left: -48px;
  margin-top: 13px;
}

.ViewOwnProfileModels__bloc {
  margin-top: 6%;
  margin-bottom: -28px;
  padding: 0 8px;
}
.ViewOwnProfileContentBloc__text > * {
  margin: 0;
}
.ViewOwnProfileContentBloc__text > img {
  width: 28px !important;
}

.ViewOwnProfileModelsBloc__text > sub {
  color: #636363;
  font-size: 14px;
  margin-top: -35px;
}

.ViewOwnProfileModels__buttonsTopBloc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  margin-top: 24px;
}
.ViewOwnProfileModels__buttonsTopBloc > button {
  min-width: 200px;
  height: 40px;
}
/* .ViewOwnProfileModels__buttonBottomBloc {
  width: 100%;
} */

/* .subscribe-button {
  width: 113%;
  margin-left: -15px !important;
} */

.ViewOwnProfileModels__photoContainer {
  width: 100%;
  margin: 0 auto;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}
.photos-content {
  padding: 7px;
}
.ViewOwnProfileModels__pictures {
  position: relative;
  min-width: 132px;
  min-height: 132px;
  /*background-color: #DDDDDD;*/
}
.ViewOwnProfileModels__pictures > span {
  display: block !important;
}
.photos-content img.img-profile-pict {
  max-width: 132px;
  display: block;
}
.ViewOwnProfileModels__pictures > img {
  max-width: 151px;
  max-height: 134px;
  margin-right: 3px;
}

.ViewOwnProfileModels__icon-absol {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
}

.ViewOwnProfileModels__noFree {
  position: absolute;
  z-index: 6666;
}
.settings-button img {
  width: 28px;
}

.ViewOwnProfileModels__ticklesIcon {
  margin-right: 4px;
  margin-left: -5px;
  width: 28px;
}

.subscribers {
  margin-left: -42px;
}

.followers {
  margin-left: -42px;
}

.following {
  margin-left: -32px;
}

.likes {
  margin-left: -42px;
}

.ViewOwnProfileModels__chevron {
  margin-left: 12px;
  margin-bottom: -5px;
}

.ViewOwnProfileContentBloc__description {
  display: none;
}

.diplaying {
  display: inherit !important;
}

.ViewOwnProfileContentBlocDescription__text {
  color: black !important;
  text-shadow: inherit !important;
  margin: 4px 0;
}

.ViewOwnProfileModels__logo {
  width: 15%;
}

.ViewOwnProfileModels__photoContainer .MuiTab-labelIcon {
  padding-top: 6px !important;
}

.ViewOwnProfileModels__photoContainer .MuiTab-labelIcon .MuiTab-wrapper {
  flex-direction: row-reverse !important;
  color: #ec636c !important;
  position: relative;
  top: 2px;
}

#all-tab-0,
#all-tab-1 {
  align-items: center !important;
}

.coralStar {
  width: 30px;
  margin-left: 5px;
  filter: invert(51%) sepia(12%) saturate(2682%) hue-rotate(314deg)
    brightness(113%) contrast(95%);
}

.profile-wall-tabs button {
  opacity: 1 !important;
  font-size: 16px !important;
  font-weight: 900 !important;
}

.profile-wall-tabs button span {
  font-size: 16px !important;
}

.profile-wall-tabs .MuiTabs-indicator {
  bottom: 15px;
}
