.button {
  float: left;
  margin: 0 5px 0 0;
  /* width: 100px; */
  width: 100%;
  height: 40px;
  position: relative;
  background-color: #ec3e7e;
  color: white;
  margin: 10px 0;
  border-radius: 5px;
}

.button label,
.button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.button input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.button input[type="radio"]:checked + label {
  background: #ffaebc;
  border-radius: 4px;
}

.button label {
  cursor: pointer;
  z-index: 90;
  line-height: 2.5em;
}

.MuiMobileStepper-dotActive {
  background-color: rgb(248 46 115) !important;
}

.instruction-ul {
  padding-left: 30px;
}

.instruction-lists {
  text-align: left;
  margin-bottom: 5px;
  opacity: 0.7;
}
