.kebabMenu-head {
  padding: 25px;
}

.kebaMenuHead {
  font-size: 16px;
  color: #ff2775;
  margin-top: 20px;
}
.kebaMenuHead.two-col-block > div {
  display: inline-flex;
  text-align: center;
  width: 50%;
}
.kebaMenuHead.two-col-block > div > div {
  display: block;
  vertical-align: top;
}
.kebaMenuHead > div > div > h4 {
  margin: 0;
}
.kebaMenuHead > div > div > p {
  margin: 8px 0;
  font-size: 16px;
  color: #323232 !important;
  text-shadow: none;
}

.MuiDrawer-paperAnchorBottom {
  margin: 0 auto;
  max-width: 500px;
  border-radius: 30px 30px 0 0;
  padding-bottom: 83px;
}

.kebabMenu__roundedHr {
  border-top: 8px solid #dfdfdf;
  border-radius: 5px;
  margin-left: calc(50% - 50px);
  width: 100px;
}

.kebabMenuContent__txt > p {
  font-size: 16px;
  color: #323232;
  text-shadow: none;
}

.kebabMenu__buttons {
  padding: 0 16px;
}

.kebabMenu__buttons > button {
  background-color: #f7f7f7;
  width: 100%;
  margin-top: 15px;
  padding: 8px;
}

.kebabMenu__buttons > button > span {
  font-size: 16px;
  text-transform: capitalize;
  color: #323232;
}

.kebabMenu__buttons .delete-btn > span {
  color: red !important;
}
