.choose-picty__container {
  width: 100%;
  min-height: 100vh;
  background-color: whitesmoke;
}
.chooseArea_header_contents,
.PictyListFull {
  padding: 0 16px;
  width: calc(100% - 32px);
  max-width: 1200px;
  margin: 0 auto;
}
.paragListPicty {
  color: black;
  text-shadow: none;
}
.pictyIcons {
  width: 100%;
  padding-top: 20px;
  background-color: white;
  border-radius: 20px;
}
.choosingArea {
  width: 100%;
  background-color: whitesmoke;
  position: fixed;
  z-index: 999;
  top: 0;
  left: auto;
  right: auto;
  padding: 16px 0;
  min-height: 120px;
  box-shadow: 2px 1px 6px lightgray;
}
.chooseAreaContent {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
  margin-top: 16px;
  left: auto;
  right: auto;
}
.chooseArea_action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}
.chooseArea_action .back__button {
  position: relative;
  left: -20px;
}
.chooseArea_action .back__button .MuiSvgIcon-root {
  width: 40px;
  height: 40px;
}
.pictyList {
  margin-top: 240px;
}

.pictyInscription {
  border: none;
  /* width: 80px; */
  height: 36px;
  border-radius: 10px;
  box-shadow: 1px 1px 6px grey;
  font-weight: 600;
  font-size: 18px;
}
.pictyInscription:hover {
  box-shadow: 5px 3px 12px gray;
  font-weight: 800;
  transition: all 0.6s ease-in-out;
}
