::-webkit-scrollbar {
  display: none;
}

.camera-picture-content {
  padding: 15px 5px 95px;
  background-color: black;
  margin: 0px;
  height: 93%;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.div-buttons-picture-component {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  left: 0;
  bottom: 134px;
}

.div-button-take-picture-component {
  background-color: gray;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 6px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-take-picture-component {
  background-color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid red;
  color: white;
}

.blink_me_pict {
  animation: blinker 1s linear;
}

@keyframes blinker {
  50% {
    background-color: blue;
  }
  50% {
    background-color: red;
  }
}

.div-button-flip-component {
  position: absolute;
  right: 45px;
}

.button-flip-camera-component {
  border-radius: 50%;
  height: 44px;
  width: 44px;
  border: none;
}

.div-button-close-component {
  position: absolute;
  left: 45px;
}

.button-close-component {
  background-color: #727a87;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
