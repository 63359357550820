/*
.video-container {
  max-height: 800px;
  background-color: black;
  padding-top: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 3%;
} */

.video-container {
  /* background-color: black; */
  /* padding-top: 10%; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* flex-direction: column; */
  padding-bottom: 53%;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  position: relative;
  z-index: 0;
}

video.video-picture {
  margin-bottom: -31% !important;
}

video {
  margin-top: -176px !important;
}

.button-video-start {
  background-color: red;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  border: 1px solid white;
  color: white;
}

.button-video-stop {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1px solid white;
  color: white;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    background-color: blue;
  }

  50% {
    background-color: red;
  }
}

.div-button-start {
  background-color: gray;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 6px;
  position: absolute;
  bottom: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: -8%; */
}

.div-button-stop {
  background-color: gray;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 6px;
  position: absolute;
  bottom: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 42%;
}

.div-button-flip {
  position: absolute;
  right: 45px;
  bottom: 48px;
}

.button-flip-camera {
  border-radius: 50%;
  height: 44px;
  width: 44px;
  border: none;
}

.div-button-close {
  position: absolute;
  left: 45px;
  bottom: 44px;
}

.button-close {
  background-color: #727a87;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.downVid {
  position: absolute;
  width: 284px;
  height: 123px;
  border-radius: 7px;
  /* box-shadow: 1px 3px 234px black; */
  top: 30%;
  left: 17%;
  z-index: 999999;
}

#vid2 {
  margin-left: 11px;
  margin-top: 5px !important;
  width: 118px;
  height: 118px;
}

.video1 {
  position: relative;
  bottom: -100px;
  width: 99vw;
  height: 90vh;
}

.hidden {
  display: none !important;
}

/* #movieTop {
  position: absolute;
  margin-bottom: 176px;
} */
.overlay {
  background-color: rgba(0, 0, 0, 0.9);
  width: 412px;
  height: 100vh;
  position: absolute;
  z-index: 999999;
  top: 0;
}

.recordVid {
  position: absolute;
  width: 284px;
  height: 123px;
  border-radius: 7px;
  /* box-shadow: 1px 3px 234px black; */
  top: 66%;
  left: 17%;
  z-index: 999999;
}

.button-content {
  /* margin-top: 269px; */
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  bottom: 25px;
}

.text-publi-video {
  min-height: 91px;
  min-width: 223px;
  outline: none;
  border: none;
  color: black;
  margin-top: 0px;
  padding: 15px;
  margin-left: 14px;
  background: #f7f7f7;
  font-family: "Roboto";
}

.movieTopContent__header {
  display: flex;
  width: 94%;
  align-items: center;
  justify-content: space-between;
  padding: 13px;
  top: -20px !important;
  box-shadow: 0 2px 40px #0000000d;
  background-color: #f7f7f7;
  height: 43px;
  z-index: 99;
  position: fixed;
  padding-top: 25px;
}

.movieTopContent__header > h4 {
  font-size: 19px;
  color: #636363;
  margin-top: 30px;
  font-weight: 600;
  margin-left: 17px;
}

.videoStudio__postSection {
  display: flex;
  background: #f7f7f7;
  box-shadow: 0 6px 6px #8a959e33;
  border-radius: 0 0 7px 7px;
  position: fixed;
  height: 162px;
  top: 58px;
  width: 100%;
  z-index: 888;
  padding-bottom: 95px;
}

.videoStudio__postButton {
  margin-top: 32px;
  color: #f72c3f !important;
  text-transform: capitalize !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.videoStudio__pictyFilter {
  padding-top: 30vh !important;
  background: #f7f7f7;
}

.videoStudio__selectedPicty {
  position: absolute;
  margin-top: 131px;
  width: 98%;
}

.videoStudioSelectedPicty__title {
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.videoStudioSelectedPicty__icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 88% !important;
  margin-left: 26px;
  margin-top: 3px;
}

.videoStudioSelectedPicty__title > b {
  font-size: 17px;
  color: #000000;
}

.videoStudioSelectedPicty__title > p {
  color: #323232 !important;
  font-size: 16px !important;
  text-shadow: none !important;
  margin-left: 5px;
}

.selectedPicty__avatarBase {
  opacity: 0.1;
}

textarea.text-publi-video::placeholder {
  font-weight: 600;
}

.videoStudioSelectedPicty__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #f72c3f;
  color: white;
  width: 14.09px;
  height: 14.09px;
  border-radius: 50%;
  z-index: 999999;
  margin-left: -45px;
  margin-top: -30px;
}

.videoStudioSelectedPicty__deleteButton {
  font-size: 14px;
  font-weight: bold;
  transform: rotate(45deg);
}

.videoStudioSelectedPicty__selectedAvatar {
  /* border: 1px solid #e94057; */
  width: 42px !important;
  height: 42px !important;
}

.videoStudioSelectedPictyList__names {
  position: relative;
  margin-top: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #858585;
  opacity: 0.9;
  padding-bottom: 31px;
}

.videoStudioSelectedPictyList__names > sub:first-child {
  margin-left: 20px;
}

.videoStudioSelectedPictyList__names > sub:nth-child(2) {
  margin-left: -56px;
  margin-right: -16px;
}

.videoStudioSelectedPictyList__names > sub:nth-child(3) {
  margin-left: -39px;
}

.videoStudioSelectedPictyList__names > sub:nth-child(4) {
  margin-left: -53px;
  margin-right: -12px;
}

.videoStudioSelectedPictyList__names > sub:last-child {
  margin-right: 19px;
  margin-left: -39px;
}
