.button-take-picture {
  position: absolute;
  left: 32%;
  bottom: 13px;
  background: none;
  border: none;
}

.button-take-video {
  position: absolute;
  left: 58%;
  bottom: 19px;
  background: none;
  border: none;
}

.bottomNavigation__close {
  /* position: absolute;
  right: 35px;
  bottom: 15px; */
}

.bottomNavigationStudioModels__content {
  position: fixed;
  bottom: 0 !important;
  width: 100%;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(201, 201, 201, 0.8) 20%,
    rgba(0, 0, 0, 1) 100%
  );
}
