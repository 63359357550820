/* IPHONE 8 SECTION */

/* IPHONE 6/7/8PLUS */
@media screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .searchDefaultViewIphone {
    padding-top: 13vh !important;
  }

  .searchDefaultContainerIphone {
    padding-bottom: 120px !important;
  }

  .settingFeetLovers__contentIphone > .settingFeetLovers__contentList > p,
  .settingFeetModels__contentIphone > .settingFeetModels__contentList > p {
    font-size: 13px !important;
  }

  .filtersContainerIphone {
    width: 100%;
    margin-top: 6vh;
  }

  .filtersContainerIphone > .searchFilter__body > .searchFilter__inscription {
    width: 95% !important;
  }

  .homeStudioIphone {
    height: 85vh;
  }

  .picture-logoIphone {
    margin-top: -18%;
    margin-bottom: 50px;
  }

  .bottomNavigationStudioModels__contentIphone {
    margin-bottom: 0px !important;
  }

  .bottom-crop-menuIphone {
    width: 100% !important;
    left: 0px !important;
    margin-bottom: 0px !important;
  }

  .col-6-filtersIphone
    > .rec-carousel-wrapper
    > .rec-carousel
    > .rec-slider-container
    > .rec-slider
    > .rec-swipable
    > .rec.rec-carousel-item
    > .rec-item-wrapper
    > .img-fuild-filters {
    max-width: 140px;
    max-height: 140px;
  }

  .col-6-filtersIphone
    > .rec-carousel-wrapper
    > .rec-carousel
    > .rec-slider-container
    > .rec-slider
    > .rec-swipable
    > .rec.rec-carousel-item {
    margin-right: -59px !important;
  }

  .postPublication__contentIphone {
    height: 246px;
  }

  .postPublication__contentIphone > .postPublicationcontent__selectedPicty {
    margin-top: -24px;
  }

  .postPublicationPictiesChoice__favoritesIphone {
    /* margin-top: -51px !important; */
    width: 100%;
  }

  .postPublicationPictiesChoice__favoritesIphone > .favoritePicty__container {
    width: 100% !important;
  }

  .postPublicationPictiesChoice__favoritesIphone
    > .searchFilter__body
    > .searchFilter__inscription {
    width: 95% !important;
  }

  .ViewOwnProfileModels__containerIphone {
    padding-bottom: 322px !important;
  }

  .ViewOwnProfileModels__photoContainerIphone {
    margin-left: -42px !important;
    padding-bottom: 125px;
  }

  .ViewOwnProfileModels__photoContainerIphone > .photos-content {
    width: 136px !important;
    height: 136px !important;
  }

  .ViewOwnProfileModels__photoContainerIphone
    > .photos-content
    > .ViewOwnProfileModels__pictures
    > .img-profile-pict {
    max-width: 134px !important;
    max-height: 134px !important;
  }

  .postPublicationPictylist__buttonIphone {
    margin-left: -54px !important;
  }

  .postPublication__contentIphone
    > .postPublicationcontent__selectedPicty
    > .postPublicaionPictyList__names {
    margin-top: -41px !important;
  }

  .notSwippedIphone > .notificationsModelsComments__content,
  .notSwippedIphone > .notificationsModelsSubscribes__content {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .notifications__footerIphone {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: contents;
  }

  .notifications__footerIphone > button {
    margin-left: -36px;
    margin-top: -26px !important;
    margin-bottom: -9px !important;
    width: 55px !important;
    height: 55px !important;
  }

  .notificationsNotDeployed {
    padding-top: 17px !important;
    padding-bottom: 0;
  }

  .marginIphone {
    padding-top: 23px !important;
  }

  .Withdraw__center-buttonIphone > button > span > h4 {
    margin-top: 0;
  }

  .bankAccount__Button {
    margin-left: 32px !important;
  }

  .notOk > span {
    font-size: 13px;
  }
}

/* XR SERIE SECTION */
@media screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
  .searchDefaultViewIphone {
    padding-top: 12vh !important;
  }

  .searchDefaultContainerIphone {
    padding-bottom: 120px !important;
  }

  .settingFeetLovers__contentIphone > .settingFeetLovers__contentList > p,
  .settingFeetModels__contentIphone > .settingFeetModels__contentList > p {
    font-size: 16px !important;
  }

  .filtersContainerIphone {
    width: 100%;
    margin-top: 6vh;
  }

  .filtersContainerIphone > .searchFilter__body > .searchFilter__inscription {
    width: 95% !important;
  }

  .homeStudioIphone {
    height: 85vh;
  }

  .picture-logoIphone {
    margin-top: -18%;
    margin-bottom: 50px;
  }

  .bottomNavigationStudioModels__contentIphone {
    margin-bottom: 22px !important;
  }

  .bottom-crop-menuIphone {
    width: 100% !important;
    left: 0px !important;
    margin-bottom: 22px !important;
  }

  .col-6-filtersIphone
    > .rec-carousel-wrapper
    > .rec-carousel
    > .rec-slider-container
    > .rec-slider
    > .rec-swipable
    > .rec.rec-carousel-item
    > .rec-item-wrapper
    > .img-fuild-filters {
    max-width: 140px;
    max-height: 140px;
  }

  .col-6-filtersIphone
    > .rec-carousel-wrapper
    > .rec-carousel
    > .rec-slider-container
    > .rec-slider
    > .rec-swipable
    > .rec.rec-carousel-item {
    margin-right: -59px !important;
  }

  .postPublication__contentIphone {
    height: 246px;
  }

  .postPublication__contentIphone > .postPublicationcontent__selectedPicty {
    margin-top: -24px;
  }

  .postPublicationPictiesChoice__favoritesIphone {
    /* margin-top: -51px !important; */
    width: 100%;
  }

  .postPublicationPictiesChoice__favoritesIphone > .favoritePicty__container {
    width: 100% !important;
  }

  .postPublicationPictiesChoice__favoritesIphone
    > .searchFilter__body
    > .searchFilter__inscription {
    width: 95% !important;
  }

  .ViewOwnProfileModels__containerIphone {
    padding-bottom: 322px !important;
  }

  .ViewOwnProfileModels__photoContainerIphone {
    margin-left: -42px !important;
    padding-bottom: 125px;
  }

  .ViewOwnProfileModels__photoContainerIphone > .photos-content {
    width: 136px !important;
    height: 136px !important;
  }

  .ViewOwnProfileModels__photoContainerIphone
    > .photos-content
    > .ViewOwnProfileModels__pictures
    > .img-profile-pict {
    max-width: 134px !important;
    max-height: 134px !important;
  }

  .postPublicationPictylist__buttonIphone {
    margin-left: -54px !important;
  }

  .postPublication__contentIphone
    > .postPublicationcontent__selectedPicty
    > .postPublicaionPictyList__names {
    margin-top: -41px !important;
  }

  .notSwippedIphone > .notificationsModelsComments__content,
  .notSwippedIphone > .notificationsModelsSubscribes__content {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .notifications__footerIphone {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: contents;
  }

  .notifications__footerIphone > button {
    margin-left: -36px;
    margin-top: -26px !important;
    margin-bottom: -9px !important;
    width: 55px !important;
    height: 55px !important;
  }

  .notificationsNotDeployed {
    padding-top: 17px !important;
    padding-bottom: 0;
  }

  .marginIphone {
    padding-top: 23px !important;
  }

  .Withdraw__center-buttonIphone > button > span > h4 {
    margin-top: 0;
  }

  .bankAccount__Button {
    margin-left: 32px !important;
  }

  .notOk > span {
    font-size: 14px;
  }
}
