.SendTickles__paper {
  position: absolute;
  background-color: white;
  /* box-shadow: 2px 2px 6px black; */
}

.modal-SendTickles {
  min-width: 300px;
  border-radius: 7px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  border-radius: 20px;
}

.modal-SendTickles:focus {
  outline: none !important;
}

.SendTickles__content {
  width: 100%;
}

.SendTickles__header {
  /*background-image: linear-gradient(*/
  /*  to right,*/
  /*  rgba(255, 39, 117, 1),*/
  /*  rgba(255, 87, 104, 1)*/
  /*);*/
  border-radius: 7px 7px 0 0;
  height: 68px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SendTickles__header > img {
  width: 36px;
}

.SendTickles__header > h2 {
  color: black;
  font-family: "Roboto";
  font-size: 17px;
}

.SendTickles__body {
  width: calc(100% - 32px);
  align-items: center;
  justify-content: center;
  display: inline-block;
  padding: 16px;
}

.SendTickles__top > p,
.SendTickles__center > p,
.SendTickles__bottom > p {
  color: #323232 !important;
  text-shadow: inherit;
  font-size: 16px;
  text-align: center;
}

.SendTickles__bottom > h3 {
  font-weight: 600;
  font-size: 16px;
  color: #ff2775;
  margin-top: 33px;
  margin-bottom: -12px;
}

.SendTickles__input {
  height: 27px;
  width: calc(100% - 24px) !important;
  background: #f7f7f7 !important;
  box-shadow: none !important;
  border-radius: 10px;
  margin-bottom: 22px;
}

.SendTickles__center > h4 {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 6px;
  font-weight: 500;
}

.SendTickles__center-button {
  width: 100%;
}

.SendTickles__bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.SendTickles__center-button > button {
  color: white !important;
  text-transform: capitalize;
  height: 47px;
  width: 124px;
}

.SendTickles__center-button > button:first-child {
  margin-right: 20px;
  background: #636363 !important;
}

.SendTickles__center-button > button:last-child {
  background-image: linear-gradient(
    to right,
    rgb(248, 46, 115),
    rgb(249, 80, 107)
  );
}

.send-tickles-prices {
  display: flex;
  flex-wrap: wrap;
  margin-top: 22px;
  gap: 10px;
  justify-content: center;
}

.send-tickles-prices > .send-tickles-prices-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 23.3333333333%;
  margin-bottom: 5px;
  cursor: pointer;
}

.send-tickles-prices > .send-tickles-prices-item div:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: 0.01em solid #9f9f9f;
  width: 80%;
  padding: 10px;
  border-radius: 20px;
  justify-content: flex-end;
  gap: 5px;
}

.send-tickles-prices
  > .send-tickles-prices-item
  div:first-child
  span:nth-child(2) {
  font-weight: 700;
}

.send-tickles-prices > .send-tickles-prices-item > span {
  font-size: 0.75rem;
  margin-top: 0.313rem;
}

.send-selected-tickle-product > .send-tickles-prices-item-icon {
  background-color: #f8cbd0;
  outline-color: #ff7e9d !important;
}

/* .SendTickles__button>span {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
} */
/* span.MuiButton-label>h4 {
  text-align: center;
  margin-top: 6%;
} */
