.homeStudioCrop {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: inherit;
  width: auto;
  height: 100%;
}

.publication-picture-cropper {
  max-width: 100%;
  max-height: 93%;
}

.bottom-crop-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(201, 201, 201, 0.8) 20%,
    rgba(0, 0, 0, 1) 100%
  );
  height: 76px !important;
}

.bottom-crop-tab {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.crop-icon,
.cropMenu {
  margin-top: 14px;
  border: none;
  background: none;
}

.header-crop-view {
  position: fixed;
  top: 0;
  width: 100%;
}

.importantWords {
  color: #f82f72;
}

.hr-crop {
  position: relative;
  margin-top: -17px;
  margin-bottom: 44px;
  margin-left: -48px;
  width: 99%;
}

.text-picture-step {
  font-size: 14px;
}

.text-picture-stepFr {
  font-size: 13px;
}

.chevron {
  position: absolute;
  left: 30px;
  top: 76px;
}

.chevron > button > svg.MuiSvgIcon-root {
  width: 31px;
  height: 31px;
}
