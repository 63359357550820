.image-cropper-content {
  padding: 15px;
}

.image-cropper-content input {
  text-align-last: center;
}

@media screen and (max-width: 375px) {
  .image-cropper-dialog .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 180px);
  }
}
