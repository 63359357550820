.MuiPickersToolbar-toolbar {
  background-color: #ff5768 !important;
}

.MuiPickersDay-daySelected {
  background-color: #ff5768 !important;
}

.MuiButton-textPrimary {
  color: #ff5768 !important;
}

.has_profile_picture_waiting_moderation {
  text-align: center;
  font-size: 0.75rem;
  color: rgb(255, 87, 104);
  margin-bottom: 20px;
}

.has_profile_picture_waiting_moderation.edit-profile-moderation {
  margin-bottom: 50px;
}
