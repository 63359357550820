.searchFilter__search {
  color: #f72c3f !important;
  text-transform: capitalize;
  font-size: 18px;
}

.searchFilter__body {
  width: 100%;
  max-width: 440px;
  margin: 32px auto;
  background: #f7f7f7;
}

.searchFilter__content .searchFilter__body {
  padding-top: 135px;
}

.searchFilter__body > div {
  width: calc(100% - 16px);
  margin: 8px;
  text-align: center;
}

.filter_options.picty__container {
  justify-content: space-evenly;
}

.filter_options.picty__container > div {
  width: 100px;
  min-width: unset;
}

.searchFilter__daily-footer {
  align-items: center;
  justify-content: center;
  display: flex;
}

.searchFilter__daily-body {
  width: calc(100% - 20px);
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
}

.searchFilter__h2 {
  font-size: 10px;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.searchFilter__h2 button {
  padding: 0;
}

.searchFilter__textPict {
  display: flex;
  margin-top: -13vh;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 12px;
  color: #858585;
  margin-left: 40px;
  position: relative;
}

.hidden-accordion {
  display: none;
}

.hidden-content {
  margin-top: -9px !important;
}

.searchFilter__sophisticated-body {
  width: 89%;
  height: 129px;
  margin-left: 20px;
  margin-bottom: 122px;
}

div#panel1a-header {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.MuiAccordionDetails-root {
  display: flex;
  padding: 8px 16px 16px;
  margin-top: -11vh;
  align-items: center;
  justify-content: flex-start;
}

.searchFilter__daily-textPict-sub {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  margin-top: 12px;
  color: #858585 !important;
}

.searchFilter__daily-textPict-sub > p {
  margin-top: 78px;
  margin-right: 24px;
  color: #858585 !important;
}

.MuiAccordion-rounded:last-child {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.MuiAccordion-rounded:first-child {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.searchFilter__p {
  color: #858585 !important;
  text-shadow: none !important;
}

.Mui-expanded.MuiIconButton-edgeEnd {
  margin-top: 10vh;
}

.searchFilter__sophistiq-textPict {
  margin-left: -19px;
  font-size: 10px;
  margin-top: -79px;
}

.searchFilter__sophistiq-body {
  width: 100%;
}

.searchFilter__sophisticated-footer {
  margin-left: 6%;
}

.searchFilter__sophisticated-footer > h2 {
  margin-left: 5px;
  margin-bottom: 9px;
  margin-top: 8vh;
  font-size: 18px;
}

.selectedPicty {
  border: 2px solid #e94057 !important;
  border-radius: 50% !important;
}

.searchFilter__content .postPublicationcontent__selectedPicty {
  position: fixed;
  width: 100%;
  background: #f7f7f7;
  box-shadow: 0 6px 6px rgb(138 149 158 / 20%);
  border-radius: 0 0 7px 7px;
  z-index: 2;
  padding: 15px;
  top: 50px;
}

.postPublicationcontent__selectedPictyText {
  margin-top: 0 !important;
}

.hr-sep-filters {
  width: 100%;
  opacity: 0.2;
}

.searchFilter__daily-body.hidden-daily-body > hr {
  display: none;
}

.hidden-daily-body > .filter_options {
  display: none;
}

.searchFilterBarfootBody__h2 {
  width: 100%;
}

.searchFilterPersonalityBody__h2 {
  width: 100%;
}
