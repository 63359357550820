.messageSearch__container {
  width: 100%;
  height: 100%;
}

.messageSearch__search {
  margin-bottom: 16px;
}
.messageSearch__textInput {
  display: block;
  padding: 8px 16px;
  font-size: 18px;
}
.messageSearch__name {
  cursor: pointer;
}
#searchIcon {
  position: absolute;
  left: 25px;
}

.messageSearch__contentText {
  margin: 16px 16px 0 16px;
  font-size: 18px;
}
