.homeLover__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 100%;
  background-color: #f7f7f7;
}

.homeLover__header {
  position: fixed;
  top: 0px;
  background-color: #f7f7f7;
  width: 100%;
  text-align: center;
  z-index: 11;
  padding-bottom: 10px;
  height: 37px;
}

.homeLover__header > img {
  margin-top: 10px;
  width: 61px;
}

.homeLover__top {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 10px #00000040;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 7px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  height: 88px;
  cursor: pointer;
}

.homeLover__top > img {
  width: 60px;
  height: 60px;
  margin-left: 15px;
  margin-right: 15px;
}

.homeLover__top-text > h4 {
  color: #323232;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

.homeLover__top-text {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
}

.homeLover__top-text > p {
  color: #858585;
  font-size: 12px;
  text-shadow: none;
  margin-top: -11px;
}

.homeLover__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.homeLover__content > h3 {
  font-size: 18px;
  color: #000000;
  margin-left: -68vw;
  margin-bottom: 9px;
}

.homeLover__grid-picture {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 79px;
  gap: 10px;
}

.homeLover__photo {
  max-width: 160px;
  /* max-height: max-content; */
  height: 150px;
  width: 150px;
  object-fit: cover;
  /* object-position: center; */
  margin-right: 15px;
  border-radius: 12px;
}

.homeLover__avatar {
  max-width: 42px;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  object-fit: cover;
  /* border: 1px solid #fe294e; */
}

.homeLover__bottom-publication {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.homeLover__avatarName {
  font-size: 16px;
  color: #323232;
  margin-left: 8px;
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 0;
}

.homeLover__follow-button {
  background-image: linear-gradient(
    to right,
    rgba(255, 39, 117, 1),
    rgba(255, 87, 104, 1)
  );
  font-size: 15px !important;
  border-radius: 25px !important;
  width: 93%;
  height: 32px;
  margin-bottom: 14% !important;
  color: white !important;
  margin-top: 10px !important;
}

.homeLover__follow-button > .MuiButton-label {
  text-transform: capitalize !important;
}

.unfollow-button {
  background-color: #ffffff !important;
  background-image: none;
  color: #000000 !important;
  border: 1px solid !important;
}

.homeLover__grid-picture > * {
  /* flex: 1 1 0px */
}

.homeLover__pictures-publications {
  min-width: 150px;
  /* width : 150px; */
}

.homeLover__pictures-publications > img {
  width: 150px;
}

.ambassadress {
  margin: 0;
  margin-left: 8px;
  font-size: 12px;
  color: #ff5768;
  font-weight: 600;
}

.normalize-text,
.normalize-text * {
  text-transform: none !important;
}
