.feet_details > img {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.feetDetails__container {
  height: calc(100vh - 64px);
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.feetDetails__paper {
  /* margin-top: 8px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 70px;
}

.feet_details_page_fixed_header {
  position: fixed !important;
  background-color: #ffffff;
  top: 0 !important;
  z-index: 999;
  width: 100%;
}

.feet_details_page_fixed_header > button {
  left: -4px;
  position: absolute;
  min-width: auto;
  top: calc(50% - 22px);
}

.feet_details_page_fixed_header > h4 {
  text-align: center;
  margin: 10px 0;
}

.feetDetails__Text {
  font-family: roboto !important;
  font-weight: bold !important;
  font-size: 18px !important;
  color: black;
  margin-top: 22px !important;
  margin-bottom: 16px !important;
}

.feetDetails__TextRegular {
  font-weight: 400 !important;
  color: #636363;
  margin-top: 39px;
}

.feetDetails__form {
  width: 100%;
  margin-top: 15px;
}

.feetDetails__submit {
  margin: 3px 0 2px;
}

.feetDetails__imgLogo {
  width: 53%;
  margin-bottom: 2vh;
  margin-top: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feetDetails__imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87px;
  height: 87px;
  background-color: white;
  border-radius: 21%;
  box-shadow: 1px 3px 6px #80808091;
}

.feetDetails__loginText {
  font-family: roboto;
  font-weight: bold;
  font-size: 15px;
  color: black;
  margin-top: 22px;
}

.feetDetails__feetSizeInput {
  color: #000000;
  margin-left: 5px;
  /* font-size: 17px; */
  margin-bottom: 14px;
  margin-top: 16px;
  font-weight: 500;
}

.feetDetails__firstInput {
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 0 10px #00000040;
  display: flex !important;
  height: 60px;
  padding: 14px;
  font-size: 16px !important;
}

.feetDetails__firstInput > input {
  box-shadow: none !important;
}

.feetDetails__pict_container {
  box-shadow: 0 0 10px #00000040;
  margin-top: 33px;
  margin-bottom: 20px;
  padding: 12px;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 31px;
}
.feetDetails__pict_container.picty__container > div {
  min-width: unset;
}
.feetDetails__submit {
  margin-top: 24px;
}

.feetDetails__textPict {
  display: flex;
  margin-top: -12vh;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  color: #323232;
}

.feetDetails__textPict > p {
  color: #323232 !important;
  text-shadow: none !important;
}

.feetDetails__feetParticularity {
  color: #000000;
  margin-left: 5px;
  /* font-size: 17px; */
  margin-top: 5vh;
  margin-bottom: -2vh;
  font-weight: 500;
}

.choose_picty_bloodline > div {
  flex: 1;
}
