.BuyTicklesModal__paper {
  position: absolute;
  width: 268px;
  height: 302px;
  background-color: white;
  /* box-shadow: 2px 2px 6px black; */
  padding: 10px 17px 37px;
}

.modal-BuyTicklesModal {
  border-radius: 7px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
}

.modal-BuyTicklesModal:focus {
  outline: none !important;
}

.BuyTicklesModal__content {
  width: 101%;
  margin-left: -17px;
  margin-top: -113px;
}

.BuyTicklesModal__header {
  background-image: linear-gradient(
    to right,
    rgba(255, 39, 117, 1),
    rgba(255, 87, 104, 1)
  );
  border-radius: 7px 7px 0 0;
  height: 68px;
  width: 112.7%;
  margin-top: -26px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: -9px;
}

.BuyTicklesModal__header > img {
  width: 36px;
}

.BuyTicklesModal__header > h2 {
  color: white;
  font-family: "Roboto";
  font-size: 17px;
  margin-left: 51px;
}

.BuyTicklesModal__body {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: inline-block;
  margin-left: 5px;
  height: 13vh;
}

.BuyTicklesModal__top > p,
.BuyTicklesModal__center > p,
.BuyTicklesModal__bottom > p {
  color: #323232 !important;
  text-shadow: inherit;
  font-size: 16px;
  text-align: center;
}

.BuyTicklesModal__center-button {
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
  margin-top: 24px;
}

.BuyTicklesModal__bottom > h3 {
  font-weight: 600;
  font-size: 16px;
  color: #ff2775;
  margin-top: 33px;
  margin-bottom: -12px;
}

.BuyTicklesModal__center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.BuyTicklesModal__input {
  height: 27px !important;
  width: 85%;
  background: #f7f7f7 !important;
  box-shadow: none !important;
  border-radius: 10px;
  margin-bottom: 22px;
}

.BuyTicklesModal__center > h4 {
  margin-left: -70%;
  margin-top: 15px;
  margin-bottom: 6px;
  font-weight: 500;
}

.BuyTicklesModal__bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.BuyTicklesModal__center-button > button {
  color: white !important;
  text-transform: capitalize;
  height: 47px;
  width: 98%;
  margin-bottom: 20px;
}

.BuyTicklesModal__center-button > button:last-child {
  background: #636363 !important;
}

.BuyTicklesModal__center-button > button:first-child {
  background-image: linear-gradient(
    to right,
    rgb(248, 46, 115),
    rgb(249, 80, 107)
  );
}

.BuyTicklesModal__top {
  margin-top: 32px;
  margin-bottom: 32px;
}

/* span.MuiButton-label>h4 {
  text-align: center;
  margin-top: 6%;
} */
