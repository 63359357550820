.commentsSection__innerContent {
  position: relative;
  height: calc(100% - 200px);
}

.commentBubbles_list {
  height: calc(100% - 140px);
  overflow: auto;
}
.commentModal__container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  margin-top: 20px;
  padding: 10px;
}

.commentModal__container > .MuiButton-text > .MuiButton-label {
  font-size: 12px;
  text-transform: lowercase;
  color: white;
  font-weight: bold;
}

.commentModal__container > .MuiButtonBase-root {
  min-height: 28px;
  height: 32px;
  text-align: center;
  width: 100px;
  margin-left: 8px;
  margin-top: 0 !important;
}

.commentModal__textArea {
  outline: none;
  border: none;
  color: black;
  margin-top: 0px;
  padding: 15px;
  background: #f7f7f7;
  font-family: "Roboto";
}
