.bottomNavigation__content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomNavigation__innerContent {
  position: fixed;
  width: 100% !important;
  left: 0 !important;
  bottom: 12px !important;
}

/* .bottomNavigation__root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
} */

.bottomNavigationStudioModels__content .bottomNavigation__root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.bottomNavigation__innerContent .bottomNavigation__root {
  background: rgba(255, 255, 255, 0.7) !important;
  border-radius: 15px;
  max-width: 500px;
  margin: 0 auto;
  padding: 8px;
  -webkit-box-shadow: -3px 0 15px 0 rgba(165, 165, 165, 1);
  -moz-box-shadow: -3px 0 15px 0 rgba(165, 165, 165, 1);
  box-shadow: -3px 0 15px 0 rgba(165, 165, 165, 1);
}

.bottomNavigation__actions,
.bottomNavigation__root .MuiBadge-root {
  position: relative;
  color: white !important;
  font-size: 18px;
  max-width: 48px !important;
  min-width: 48px !important;
}
.bottomNavigation__root .MuiBadge-root .MuiBadge-badge {
  position: absolute;
  top: unset;
  bottom: -12px;
  right: 50%;
}
.MuiBottomNavigationAction-root.Mui-selected {
  color: black !important;
}
.bottomNavigation__actions {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 32px;
  padding: 0 8px !important;
}

.MuiBottomNavigationAction-wrapper svg {
  width: 10px;
  position: absolute;
  color: red;
  bottom: -5px;
}

.bottomNavigation__actions-first {
  background-image: url(../../../../assets/images/bottom-navigation/lovers/home.png);
}

.bottomNavigation__actions-first-selected {
  background-image: url(../../../../assets/images/bottom-navigation/lovers/selected/home-selected.png);
}

.bottomNavigation__actions-second {
  background-image: url(../../../../assets/images/bottom-navigation/lovers/search.png);
}

.bottomNavigation__actions-second-selected {
  background-image: url(../../../../assets/images/bottom-navigation/lovers/selected/search-selected.png);
}

.bottomNavigation__actions-center {
  background-image: url(../../../../assets/images/bottom-navigation/lovers/subscriptions.png);
}

.bottomNavigation__actions-center-selected {
  background-image: url(../../../../assets/images/bottom-navigation/lovers/selected/subscriptions-selected.png);
}

.bottomNavigation__message {
  background-image: url(../../../../assets/images/bottom-navigation/lovers/messages.svg);
}

.bottomNavigation__message-selected {
  background-image: url(../../../../assets/images/bottom-navigation/lovers/selected/messages-selected.svg);
}

.bottomNavigation__notification {
  background-image: url(../../../../assets/images/bottom-navigation/models/notifications.svg);
}

.bottomNavigation__notification-selected {
  background-image: url(../../../../assets/images/bottom-navigation/models/notifications-selected.svg);
}

.bottomNavigation__studio {
  background-image: url(../../../../assets/images/bottom-navigation/models/studio.svg);
}

.bottomNavigation__studio-selected {
  background-image: url(../../../../assets/images/bottom-navigation/models/studio-selected.svg);
}

.bottomNavigation__actions-last {
  background-image: url(../../../../assets/images/bottom-navigation/lovers/account.png);
}

.bottomNavigation__actions-last-selected {
  background-image: url(../../../../assets/images/bottom-navigation/lovers/selected/account-selected.png);
}
