.subscriptionDefault__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 100%;
  background-color: #f7f7f7;
}

.subscriptionDefault__header {
  position: fixed;
  top: 0px;
  background-color: #f7f7f7;
  width: 100%;
  text-align: center;
  z-index: 11;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.subscriptionDefault__header > img {
  margin-top: 19px;
  width: 61px;
}

.subscriptionDefault__header > svg {
  margin-left: -143px;
}

.subscriptionDefault__header > h4 {
  margin-left: -29px;
}

.subscriptionDefault__top {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 10px #00000040;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 7px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  height: 88px;
  cursor: pointer;
}

.subscriptionDefault__top > img {
  width: 60px;
  height: 60px;
  margin-left: 15px;
  margin-right: 15px;
}

.subscriptionDefault__top-text > h4 {
  color: #323232;
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

.subscriptionDefault__top-text {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
}

.subscriptionDefault__top-text > p {
  color: #858585;
  font-size: 12px;
  text-shadow: none;
  margin-top: -11px;
}

.subscriptionDefault__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.subscriptionDefault__content > h3 {
  font-size: 18px;
  color: #000000;
  margin-left: -68vw;
  margin-bottom: 9px;
  color: #fe294e;
  font-size: 15px;
}

.subscriptionDefault__grid-picture {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 79px;
}

.subscriptionDefault__photo {
  max-width: 160px;
  max-height: max-content;
  margin-right: 15px;
  border-radius: 12px;
}

.subscriptionDefault__avatar {
  max-width: 42px;
  border-radius: 50%;
  border: 1px solid #fe294e;
}

.subscriptionDefault__bottom-publication {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.subscriptionDefault__avatarName {
  font-size: 16px;
  color: #323232;
  margin-left: 8px;
  font-weight: 200;
}

.subscriptionDefault__follow-button {
  background-image: linear-gradient(
    to right,
    rgba(255, 39, 117, 1),
    rgba(255, 87, 104, 1)
  );
  font-size: 15px !important;
  border-radius: 25px !important;
  width: 93%;
  height: 32px;
  margin-bottom: 14% !important;
  color: white !important;
}

.subscriptionDefault__follow-button > span {
  font-size: 15px;
  text-transform: capitalize;
}
