.homePageModels__container {
  width: 100%;
  margin: 0 !important;
  position: relative;
  overflow-x: hidden !important;
}
.homePageModels__header > img {
  margin: 0 auto;
}

.homePageModels__header > button {
  padding: 8px;
  height: auto;
  top: 10px !important;
}

.homePageModels__messages {
  margin-right: 13px;
  cursor: pointer;
  padding: 10px;
  padding-left: 15px;
}

.homePageModels__messages > span > img {
  width: 28px;
}

.homePageModels__onMessages {
  background-color: red;
  width: 6px !important;
  height: 6px !important;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -3px;
}
