.rules-grid-container {
  background-color: #ffa3ac;
  border-radius: 12px;
  max-width: 100%;
}

.rules-grid-container-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 30px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.rules-publication-content-container {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.rules-publication-content-container .publication-rules:first-child {
  border-top: 1px solid #c2bdbd;
}

.publication-rules {
  margin-bottom: 0;
  border-bottom: 1px solid #c2bdbd;
  min-height: 3rem;
}

.rules-publication-modal-action {
  background-color: rgb(255 39 117) !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 50px !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  padding: 4px 20px !important;
}

.rules-publication-grid-1 {
  margin: auto 1.5rem auto 0.7rem !important;
}

.rules-publication-sub-title {
  display: flex;
  justify-content: center;
  margin: auto 0.7rem;
  align-items: center;
}

.rules-publication-sub-title q {
  padding: 3px;
  color: #ff5768;
}

.rules-publication-button-title {
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
  flex-wrap: nowrap;
  padding-right: 15px;
  padding-top: 10px;
  margin-bottom: 5px !important;
}

@media screen and (max-width: 360px) {
  .rules-publication-grid-1 {
    margin: auto 0rem auto 0.5rem !important;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
  }

  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 0 !important;
  }

  .rules-publication-sub-title {
    display: inline-block;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
  }

  .rules-publication-button-title {
    padding-top: 20px;
  }
}

@media screen and (max-width: 480px) {
  .rules-publication-sub-title {
    display: inline-block;
  }
  .MuiGrid-spacing-xs-3 > .MuiGrid-item {
    padding: 5px !important;
  }
}
