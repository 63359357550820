.followList__content {
  padding-top: 32px;
}

.followList__content > * {
  padding: 8px 0;
}

.followList__content > h4 {
  font-size: 17px;
  font-weight: 500;
}

.follow-user-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.followList__Button.pink__button {
  margin-top: 0 !important;
  font-size: 13px !important;
  min-height: 40px;
  height: 40px !important;
  padding: 4px 29px;
  min-width: 100px !important;
}

.followList__Button.default__button {
  margin-top: 0 !important;
  font-size: 13px !important;
  min-height: 40px;
  height: 40px;
  padding: 4px 20px;
  min-width: 100px !important;
}

.FollowPage__tabChoice .MuiBox-root {
  padding: 0 !important;
}

.follow-user-list .user_thumbnail_details {
  min-width: 200px;
}

.normalize-text,
.normalize-text * {
  text-transform: none !important;
}
