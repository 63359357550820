.custom_item {
  margin: 0 0 10px 0;
}

.subscriber {
  color: #ff2775 !important;
  font-weight: bold !important;
}

.warning-message-icon {
  width: 23px;
  filter: invert(60%) sepia(78%) saturate(1625%) hue-rotate(325deg)
    brightness(109%) contrast(101%);
  padding-right: 4px;
  position: relative;
  float: left;
  left: 31px;
  top: -4px;
}
