.content_not_available__container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  height: 100px;
  width: 300px;
  /* padding: 20px; */
  border-radius: 10px;
  position: relative;
  text-align: center;
}
