.feet-model-guides-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.feet-model-guides-container > p {
  padding-left: 42px;
  padding-right: 42px;
  font-weight: 400;
  line-height: 30.2px;
  font-size: clamp(18px, 4vw, 24px);
}

.i-have-agreed {
  display: flex;
  align-items: center;
  /* font-size: clamp(15px, 4vw, 18px); */
}

.i-have-agreed > span {
  font-size: clamp(15px, 4vw, 18px);
  line-height: 21px;
}
.EditProfileLover__loginButton {
  text-transform: capitalize !important;
  font-weight: 600 !important;
  height: 47px;
  background-image: linear-gradient(
    to right,
    rgb(248, 46, 115),
    rgb(249, 80, 107)
  );
  font-size: 15px !important;
  border-radius: 25px !important;
  width: 107% !important;
  margin-left: -5vw;
  margin-top: 5vh !important;
  margin-bottom: 101px !important;
}
