.MuiPickersToolbar-toolbar {
  background-color: #ff5768 !important;
}

.MuiPickersDay-daySelected {
  background-color: #ff5768 !important;
}

.MuiButton-textPrimary {
  color: #ff5768 !important;
}

.MuiInputBase-adornedEnd input#password,
.MuiInputBase-adornedEnd input#confirmNewPassword,
.MuiInputBase-adornedEnd input#newPassword,
.MuiInputBase-adornedEnd input#currentPassword {
  padding-right: 40px !important;
}

.password-view {
  position: absolute;
  right: 10px;
}

.bottom-bio-content {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.bottom-bio-content .character-count {
  margin-top: 0px;
  font-size: 12px;
  margin-right: 10px;
  position: absolute;
  right: 30px;
}
