.createMessage__container {
  width: 100%;
  /*height: 100vh;*/
  background: #f7f7f7;
}

.createMessage__header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #f7f7f7;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.createMessage__header > h4 {
  margin-left: -66px;
  color: #636363;
  font-size: 18px;
}

.createMessage__contentMessage > div {
  width: 100%;
}

/*.messagesSectionInfos {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  flex-direction: column;*/
/*}*/

.MessagesList_UsersLeft {
  display: flex;
  justify-content: flex-start;
}

.MessagesList_UsersRight {
  display: flex;
  justify-content: flex-end;
}

.MessagesList_UsersRight .createMessage__sendMessageDate {
  text-align: right;
}

.MessagesList_UsersRight .createMessage__SentMessage {
  float: right;
}

.MessagesList_UsersLeft .createMessage__SentMessage {
  float: left;
}

.MessagesList_UsersRight
  > .messagesSectionInfos
  > .messageTextSection
  > .createMessage__SentMessage {
  background: rgb(231, 231, 231) !important;
  border: none !important;
}

.MessagesList_UsersRight .messagesSectionInfos > div {
  text-align: right;
}

.MessagesList_UsersLeft .messagesSectionInfos > div {
  text-align: left;
}

.createMessage__content {
  height: calc(100vh - 180px);
  position: absolute;
  width: calc(100% - 32px);
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.createMessage__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  width: 100%;
  width: 100%;
  background: #f7f7f7;
  z-index: 99;
}

.createMessage__top .user_thumbnail_details {
  min-width: 150px;
}

.createMessage__top > button > span > img {
  width: 20px;
  margin-right: 5px;
}

.createMessage__top > button.pink__button {
  font-size: 12px !important;
  height: 32px;
  min-height: 32px;
  width: 163px !important;
  margin-top: 0 !important;
  padding: 8px;
}

.createMessage__contentMessage {
  width: calc(100% - 32px);
  height: calc(100vh - 364px);
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 16px;
  overflow: auto;
}

.createMessage__contentMessage .createMessage_no_mess_yet {
  margin-top: 25%;
}

.createMessage_no_mess_yet {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.createMessage__noMessPict {
  width: 48px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.createMessage_no_mess_yet > b {
  color: #323232;
  font-size: 16px;
  font-weight: 100;
}

.createMessage__ticklesLogo {
  width: 24px;
}

.createMessage__SentTickles {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.createMessage__SentTickles > b {
  color: #f72c3f;
  text-shadow: none;
  font-size: 18px;
  margin-right: 8px;
  font-weight: 500;
}

.createMessage__date {
  color: #606060;
  text-shadow: none;
  font-size: 12px;
  /* margin-top: 6px; */
  opacity: 0.5;
}

.createMessage__bottom {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  box-shadow: 0 -1px 0px #0000001f;
  padding: 8px 0;
  width: 100%;
  height: 45px;
}

.createMessage__bottom > span:first-child {
  width: 27.5px;
  position: absolute;
  z-index: 999;
  left: 10px;
}

.createMessage__input {
  position: relative;
  max-height: 15.04px;
  width: 251.12px;
  box-shadow: none;
  background: #ffffffcc;
  border-radius: 5px;
  border: 1px solid #efeff4;
  text-indent: 0px;
  left: 4px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  line-height: 30px !important;
}

.createMessage__inputNoMedias {
  text-indent: 0 !important;
}

.createMessageSend__bottom > .MuiButtonBase-root {
  padding: 0 10px 0 10px;
}

.createMessageSend__bottom > .dropzone__messagePicture > .MuiIconButton-root {
  padding: 0 5px;
}

.createMessage__heartNoMedias {
  opacity: 0.2;
}

.createMessage__camera {
  width: 27.5px;
}

.createMessage__picture {
  width: 26.75px;
}

.pictureDisabled {
  filter: opacity(0.5);
}

.createMessage__micro {
  width: 17.75px;
}

.createMessage__heart {
  width: 25.78px;
}

.createMessage__sendingMessButton > span {
  color: #f72c3f;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 500;
}

.createMessageSend__bottom > button:first-child {
  left: 16px;
}

.createMessage__SentMessage {
  border: 0.5px solid rgb(152, 152, 152);
  border-radius: 25px;
  /* max-width: 180px; */
  padding: 8px 12px;
  position: relative;
  /* right: -77px; */
  text-align: center;
  width: fit-content;
}

.createMessage__sendMessageDate {
  color: #606060;
  text-shadow: none;
  font-size: 12px;
  /* margin-top: 6px; */
  margin-bottom: 6px;
  opacity: 0.5;
}

.createMessage__SentMessage > p {
  margin: 0;
  color: rgba(50, 50, 50, 1);
  font-size: 16px;
  text-shadow: none;
  text-align: left;
}

.createMessage__microButton {
  filter: inherit !important;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    background-color: blue;
  }

  50% {
    background-color: red;
  }
}

.none {
  display: none;
}

.createMessage__topfr > button {
  font-size: 14px !important;
  width: 46% !important;
}

.createMessageAvatarModels {
  margin-left: -194px;
  margin-top: 15px;
  width: 50.39px;
  height: 49.88px;
  border: 1px solid #fe294e !important;
}

.createMessageModels__text {
  margin-left: -223px;
}

.createMessage__pictureSended {
  max-width: 100%;
  /* padding: 10px;
            margin-left: -14px; */
  border-radius: 25px;
}

.dropzone__messagePicture {
  display: flex;
}

.slide-in-elliptic-top-fwd {
  animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

@keyframes slide-in-elliptic-top-fwd {
  0% {
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform-origin: 50% 100%;
    opacity: 0;
  }

  100% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 50% 1400px;
    opacity: 1;
  }
}

#heart {
  position: relative;
  width: 100px;
  height: 90px;
  animation: heartbeat 1s infinite;
  margin: 0 auto;
  animation-iteration-count: 7;
}

#heart:before,
#heart:after {
  position: absolute;
  content: "";
  left: 50px;
  top: 0;
  width: 50px;
  height: 80px;
  background: #fe294e;
  -moz-border-radius: 50px 50px 0 0;
  border-radius: 50px 50px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

#heart:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(0.75);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(0.75);
  }
}

.slit-in-horizontal {
  animation: slit-in-horizontal 0.45s ease-out both;
}

@keyframes slit-in-horizontal {
  0% {
    transform: translateZ(-800px) rotateX(90deg);
    opacity: 0;
  }

  54% {
    transform: translateZ(-160px) rotateX(87deg);
    opacity: 1;
  }

  100% {
    transform: translateZ(0) rotateX(0);
  }
}

.swing-in-left-fwd {
  animation: swing-in-left-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

@keyframes swing-in-left-fwd {
  0% {
    transform: rotateY(100deg);
    transform-origin: left;
    opacity: 0;
  }

  100% {
    transform: rotateY(0);
    transform-origin: left;
    opacity: 1;
  }
}

.fade-out {
  animation: fade-out 1s ease-out both;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.custom-media-video-preview {
  background-color: #f7f7f7;
  margin-top: 0 !important;
}

.cm-file-container {
  background: #f3f3f3;
  overflow: hidden;
  width: 70px;
  height: auto;
  margin: auto;
}

.tracker-file-container {
  background: none !important;
  width: 150px !important;
}

.tracker-file-container-video {
  background: none !important;
  width: 150px !important;
  height: 115px !important;
}

.cm-file-container-video {
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin: auto;
}

.cm-file-wrapper img {
  width: 60px;
  height: auto;
  object-fit: cover;
  cursor: pointer;
}

.tracker-wrapper img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  cursor: pointer;
}

.cm-file-loader {
  width: 100%;
  text-align: center;
  padding-top: 10px;
  font-size: small;
}

.cm-file-banner {
  width: 100%;
  text-align: center;
  font-size: small;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  background-color: #f7f7f7;
  font-family: "Roboto", serif;
}

.cm-file-banner-video {
  width: 100%;
  text-align: center;
  font-size: small;
  cursor: pointer;
  z-index: 2;
  position: relative;
  background-color: #f7f7f7;
  font-family: "Roboto", serif;
}

.custom-media-wrapper .cm-file-container {
  width: 70px;
  height: auto;
}

.custom-media-wrapper .cm-file-wrapper img {
  width: 50px;
  height: 50px;
}

.cm-video-icon {
  width: 31px !important;
  height: 21px !important;
  z-index: 3;
  position: relative;
  top: 0;
}

.tracker-video-icon {
  width: 50px !important;
  top: 20px !important;
  left: 50px;
}

.cm-video-wrapper-mt15 {
  margin-top: 15px;
}

.cm-video-wrapper > .react-thumbnail-generator {
  position: relative;
  bottom: 40px;
}

.tracker-video-wrapper > .react-thumbnail-generator img {
  width: 100% !important;
  height: 115px !important;
  object-fit: cover;
  cursor: pointer;
}

.cm-video-wrapper > p {
  position: relative;
  bottom: 75px;
}

.tracker-video-wrapper > p {
  bottom: 110px;
  left: 50px;
}

.cm-video-player video {
  margin-top: auto !important;
}

.blur-image img {
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cm-file-wrapper .content {
  width: 100%;
  text-align: center;
  padding-top: 10px;
  font-size: x-small;
}

.blinking {
  display: flex;
  justify-content: center;
  animation: blink 1s infinite;
}

.delay {
  animation-delay: 0.5s;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.propose-text {
  display: inline-grid;
  text-align: left;
}

.propose-text span {
  line-height: 12px;
}

.propose-text .sub-text {
  font-size: 10px;
}

.cm-message-notification {
  text-align: center;
  font-weight: 600;
  color: rgb(247, 44, 63);
}
