.layout-container {
  width: 100%;
  height: 89%;
  position: absolute;
  top: 0;
}

.bottomLayout {
  display: none !important;
}
