.likes_item {
  margin: 0 0 15px 0;
}
.likes_item sub button {
  height: 38px !important;
  min-height: 38px !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  margin-top: 0 !important;
  min-width: 85px !important;
  text-transform: none !important;
}
