.ticklesFeetModelsAccount__container {
  height: 100vh;
}

.ticklesFeetModelsAccount__content {
  height: 100vh;
  margin-top: 66px;
}

.ticklesFeetModelsAccountContent__top {
  display: grid;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.ticklesFeetModelsAccountContentTop__tickles > * {
  display: inline-block;
  vertical-align: middle;
}

.ticklesFeetModelsAccountContentTopTickles__text {
  text-align: center;
  margin-right: 15px;
}

.ticklesFeetModelsAccountContentTopTickles__text > p {
  margin: 0;
  font-size: 16px;
  text-shadow: none;
}

.ticklesFeetModelsAccountContentTopTickles__text > b {
  font-size: 26px;
  color: #000000;
}

.ticklesFeetModelsAccount__ticklesIcon {
  width: 60px;
  margin-right: 8px;
}

.ticklesFeetModelsAccount__list {
  padding-left: 19px;
}

.ticklesFeetModelsAccountList__head > p {
  font-size: 17px;
  font-weight: 300;
  text-shadow: none;
  margin-top: 33px;
  color: #ff2775 !important;
}

.ticklesFeetModelsAccountList__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 66px;
}

.ticklesFeetModelsAccountListContent__avatar {
  width: 50.36px;
  height: 49.88px;
  margin-right: 15px;
  /*border: 1px solid #fe294e;*/
}

.ticklesFeetModelsA1ccountListContent__text {
  min-height: 47px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.ticklesFeetModelsA1ccountListContent__text > b {
  font-size: 15px;
}

.ticklesFeetModelsA1ccountListContent__text > sub {
  font-size: 12px;
  text-indent: -18px;
}

.ticklesFeetModelsAccount__list .user_list div {
  margin-bottom: 6px;
}

.ticklesFeetModelsAccount__list .user_details p {
  margin: 4px 0;
}

.disabled_withdrawal_button_text {
  text-align: center;
  font-size: 0.75rem;
  color: rgb(255, 87, 104);
}
