.accept-terms-dialog .MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  width: 25%;
  padding: 0.75rem;
}

.accept-terms-actions {
  background-color: rgb(255 39 117) !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 3.125rem !important;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  padding: 0.25rem 1.25rem !important;
}

.custom-media-modal h2 {
  text-align: center;
}

.custom-media-modal .alert-wrapper.ion-overlay-wrapper.sc-ion-alert-md {
  border-radius: 30px !important;
}

.custom-media-modal .alert-message.sc-ion-alert-md {
  text-align: center;
  color: #3a3939;
}

.custom-media-modal .alert-message.sc-ion-alert-md span {
  font-weight: 600;
  color: rgb(255, 87, 104);
}

.custom-media-modal .alert-message.sc-ion-alert-md p {
  font-size: 14px;
}

.custom-media-modal .alert-button-group.sc-ion-alert-md {
  justify-content: center;
  width: 93%;
  border-top: 1px solid rgb(255, 87, 104);
  margin: auto;
}

.custom-media-modal h2,
.custom-media-modal .alert-button-group.sc-ion-alert-md button {
  color: rgb(255, 87, 104) !important;
}

@media screen and (max-width: 600px) {
  .accept-terms-dialog .MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
    width: 100%;
  }
}
