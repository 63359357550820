.SuppressAccount__content {
  display: table;
}
.SuppressAccount__content > form {
  display: table-cell;
  vertical-align: middle;
}
.SuppressAccount__content h4 {
  margin: 8px 0;
}
.SuppressAccount__textarea {
  width: calc(100% - 32px);
  padding: 16px;
  min-height: 310px;
}
.SuppressAccount__textareaZone {
  margin-top: 32px;
}
.SuppressAccount__bottom > button {
  width: 100% !important;
}

.SuppressAccountBottom__button {
}
