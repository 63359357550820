.homePage__container {
  width: 100%;
  margin: 0 !important;
  position: relative;
  overflow-x: hidden !important;
}

.homePage__header img {
  margin: 0 auto;
}

.homePage__user__details {
  display: flex;
  font-size: 1.5rem;
  padding: 12px;
  flex-direction: column;
  cursor: pointer;
}

.homePage__user__details_username {
  display: flex;
  gap: 5px;
}

.homePage__user__details_username_text {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  text-shadow: none;
}

.homePage__user__details_username_text_ambassadress {
  color: #ff5768;
  font-size: small;
  margin: 0;
  font-weight: 600;
}

.post-bottom .action__buttons .ticle_icon {
  width: 30px !important;
}
