.feetyCertification__container {
  height: 100vh;
}

.certfiContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}
.Certification-content > p {
  color: #646464 !important;
}
.crtifEmailInput {
  margin-top: 25px;
  width: calc(100% - 32px) !important;
}
.feetyCertification__content {
  max-width: 500px;
  margin: 0 auto;
}
.feetyCertification__content > h4 {
  text-align: center;
  font-size: 16px;
}

.feetyCertification__completedStep {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.feetyCertification__completedStep > p {
  color: #323232;
  font-size: 15px;
  margin-left: 19px;
  text-shadow: none;
}

.emptyCircle {
  width: 13px;
  height: 13px;
  border: 1px solid black;
  border-radius: 50%;
}

.notOk {
  filter: sepia(10);
}
