.FollowPage__container {
  width: 100%;
}

.FollowPage__inputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.FollowPage__input {
  width: 74%;
  height: 22px;
}
#searchIconFollow {
  position: absolute;
  right: 64px;
  cursor: pointer;
}

.PrivateTabIndicator-colorSecondary-13 {
  background-color: #f50057;
  width: 50% !important;
}

.FollowPageContainer__title {
  color: #636363;
  font-size: 20px;
}

.FollowPageContainer__create {
  color: #f72c3f;
  font-size: 20px;
  text-transform: capitalize;
}

.FollowPage__tabChoice {
  background: #f7f7f7;
  margin-top: 7px;
}

.tabChoicePaper__root {
  background-color: #f7f7f7 !important;
  box-shadow: none !important;
}

.tabChoiceFollowPaper__root {
  background: #f7f7f7 !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

#simple-tab-0 {
  width: 50% !important;
}

#simple-tab-1 {
  width: 50% !important;
}

.MuiTab-wrapper {
  font-size: 16px;
  text-transform: capitalize;
}

.MuiTab-textColorInherit.Mui-selected > span {
  font-size: 16px;
}

.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  color: #000000 !important;
  font-weight: 900 !important;
}

.MuiTab-textColorInheri {
  color: #b3b3b3;
}

#simple-tabpanel-0 {
  padding-bottom: 50px;
}

#simple-tabpanel-1 {
  padding-bottom: 50px;
  min-height: 750px;
}
