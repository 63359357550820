.rsis-container div {
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.sliderModal .MuiBackdrop-root > div,
.sliderModal .MuiBackdrop-root .awssld {
  height: 100%;
}

.sliderModal .MuiPaper-root {
  max-height: 640px;
}

.sliderModal .MuiPaper-root,
.sliderModal .MuiPaper-root > div {
  height: 100%;
}

.sliderModal .MuiPaper-root .awssld {
  height: 95%;
}

.awssld .awssld__content {
  background-color: unset;
}

.awssld__bullets {
  bottom: 10px !important;
  z-index: 999999;
}

.awssld__bullets button,
.awssld__bullets--active button {
  background: #fff !important;
}

#slider-image-close {
  height: 0px;
  position: absolute;
  top: -15px;
  z-index: 99999;
  text-align: center;
  width: 100%;
  padding: 15px 0 !important;
}

#slider-image-close .MuiIconButton-root {
  color: #fff !important;
}

#slider-image-close .MuiIconButton-root .MuiIconButton-label {
  border: solid 1px;
  border-radius: 25px;
}

.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
  background-color: #fff !important;
}

.slideImage {
  max-width: 450px;
  width: 100%;
  margin: auto;
}

.closeSlide {
  position: absolute;
  top: 102px;
  right: 127px;
  width: 40px;
  height: 40px;
  opacity: 0.7;
  cursor: pointer;
  z-index: 9999;
}

.sliderModal .MuiDialog-paperWidthSm {
  max-width: unset;
  width: 100% !important;
  margin: 15px;
  background: transparent;
}

.sliderModal .MuiPaper-elevation24 {
  box-shadow: unset;
}

@media (width <= 688px) {
  .slideImage {
    width: 95%;
    margin: auto;
  }

  .closeSlide {
    position: absolute;
    top: 102px;
    right: 127px;
    width: 40px;
    height: 40px;
    opacity: 0.7;
    cursor: pointer;
    z-index: 9999;
  }
}

@media (width <= 414px) {
  .slideImage {
    width: 95%;
    margin: auto;
  }

  .closeSlide {
    position: absolute;
    top: 102px;
    right: 20px;
    width: 40px;
    height: 40px;
    opacity: 0.7;
    cursor: pointer;
    z-index: 9999;
  }
}

@media (width <= 375px) {
  .slideImage {
    width: 95%;
    margin: auto;
  }

  .closeSlide {
    position: absolute;
    top: 102px;
    right: 20px;
    width: 40px;
    height: 40px;
    opacity: 0.7;
    cursor: pointer;
    z-index: 9999;
  }
}

@media (width <= 812px) {
  .slideImage img {
    width: 100%;
    height: auto;
  }
}
