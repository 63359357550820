.chooseAccount__content {
  height: calc(100vh - 64px);
  width: 100%;
}

.chooseAccount__container {
  display: flex !important;
  flex-direction: column;
  padding: 0 !important;
  min-height: 100%;
}

.chooseAccount__paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
}

.chooseAccount__imgLogo {
  width: 51%;
  margin-bottom: 2vh;
  margin-top: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chooseAccount__Text {
  font-weight: 400 !important;
  font-size: 1.25rem !important;
  color: black;
  text-align: center;
  padding: 0 10px;
}

.chooseAccount__TextRegular {
  color: #000000;
  margin-top: 42px !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.chooseAccount__Button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px -12px;
  height: 160px;
  width: 100%;
}

.signin__redirect {
  text-align: center;
  padding: 0 12px;
}

.chooseAccount__option {
  display: block !important;
  width: 100%;
  height: 100%;
  margin: 0 12px !important;
  border-radius: 16% !important;
  text-transform: capitalize !important;
  font-weight: bold !important;
  background-color: #fff !important;
  border: 2px solid #e55071 !important;
  color: #000 !important;
}

.chooseAccount__option__selected {
  /* background-image: linear-gradient(to right, #f82e73, #f9506b) !important; */

  background-color: #e55071 !important;
  color: #fff !important;
}

.chooseAccount__option > .MuiButton-label > * {
  display: block !important;
}
