#lang-popover
  .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  top: 17px !important;
}

.lang-selector {
  z-index: 9999;
}

#simple-popover
  .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  top: unset !important;
  left: unset !important;
  right: 13px;
  bottom: 63px;
  box-shadow: unset;
  background-color: transparent;
}

.MuiAvatar-root.MuiAvatar-circular {
  margin-bottom: 4px;
  cursor: pointer;
  width: 42px !important;
  height: 42px !important;
}
