.Language-head {
  padding: 25px;
}

.LanguageHead__top {
  margin-left: -56px;
}

.MuiDrawer-paperAnchorBottom {
  border-radius: 30px 30px 0 0;
  padding-bottom: 110px;
}

.Language__roundedHr {
  border-top: 8px solid #dfdfdf;
  border-radius: 5px;
  margin-left: calc(50% - 50px);
  width: 100px;
}

.LanguageContent__title {
  color: #000000;
  font-size: 16px;
  text-align: center;
  margin-bottom: -31px;
}

.Language__buttons {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.Language__buttons > button {
  background-color: #f7f7f7;
  width: 83%;
  height: 60px;
  margin-top: 15px;
  border-radius: 10px;
}

.Language__buttons > button:last-child {
  margin-bottom: 20px;
}

.Language__buttons > button > span {
  font-size: 16px;
  text-transform: capitalize;
  color: #323232;
  margin-left: -30px;
}
