@media only screen and (max-width: 1200px) {
  .action__buttons {
    padding-left: 0;
  }
}
@media only screen and (max-width: 991px) {
  /*Model Profile*/
  .ViewOwnProfileModelsTop__picture > img {
    width: 150px;
    height: 150px;
  }
  .ViewOwnProfileModels__buttonsTopBloc {
    gap: 8px;
    margin-top: 6px;
  }
  .ViewOwnProfileModels__buttonsTopBloc > button {
    min-width: 110px;
    font-size: 0.9rem !important;
    min-height: 32px;
    height: 40px;
    line-height: 1.2;
  }
  .ViewOwnProfileModels__ticklesIcon {
    width: 20px;
    margin-top: -4px;
  }
}
@media only screen and (max-width: 767px) {
  .bottomNavigation__root {
    max-width: 85% !important;
  }
  .bottomNavigation__actions {
    background-size: 28px !important;
  }
  /* User Profile  */
  /*.viewFeetModelsProfiles__container{*/
  /*    padding: 70px 0!important;*/
  /*}*/
  .user_profile_image {
    margin-right: 16px !important;
    width: 90px !important;
    height: 90px !important;
  }
  /*  Model Studio  */
  .homeStudio {
    justify-content: space-between;
  }
  .text-welcome,
  .step-wrapper h4 {
    font-size: 1rem !important;
  }
  .step-wrapper h4 {
    line-height: 1.2;
  }
  .text-welcome > img {
    width: 55px;
    margin-bottom: -2px;
  }
  .text-center {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 540px) {
  .formSignin__container.signin_Page {
    background-size: cover;
    background-position: 30% center;
  }
  .signin_Page .formSignin__paper {
    border-radius: 20px;
  }
  .signin_Page .formSignin__form {
    margin-bottom: 25px;
  }
  .signin_Page .formSignin__roundedHr {
    margin-top: 25px;
  }
  .page_fixed_header .logo {
    width: 70px;
  }
  .settings-button img {
    width: 22px;
  }
  .createMessage__content.content__withHeader {
    padding-top: 50px !important;
  }
  .content__withHeader {
    padding-top: 70px !important;
    min-height: calc(100vh - 154px);
  }
  .page_fixed_header .back__button {
    left: -4px;
  }
  .page_fixed_header .header__rightButtton {
    right: 0;
  }
  /* Lover Homepage */
  .feet_grid_list {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
  }
  .post-item {
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;
  }
  .post-header {
    padding-right: 16px;
  }
  .post-bottom action__buttons {
    padding-left: 8px;
    max-width: 92%;
    line-height: 1;
  }
  .bottomNavigation__root .MuiBadge-root .MuiBadge-badge {
    bottom: -10px;
  }
  /*User Profile*/
  .ViewOwnProfileModels__buttonsTopBloc > button {
    min-width: 100px;
    min-height: 32px;
    height: 32px;
  }
  .ViewOwnProfileModels__ticklesIcon {
    margin-top: -2px;
  }
  .pink__button,
  .default__button,
  .white__button {
    height: 48px;
    font-size: 1rem !important;
  }
  .viewFeetModelsProfiles__bloc {
    padding: 24px 8px;
  }
  .viewFeetModelsProfilesTop__text > h4 {
    font-size: 16px !important;
  }
  .profile_counts h4 {
    font-size: 1.2rem !important;
    margin: 0 !important;
  }
  .profile_counts p {
    font-size: 0.8rem !important;
  }
  .feetLoverProfile__settingIcon {
    width: 22px;
  }
  .feetLoverProfileContent__picty {
    padding: 24px 16px;
  }
  .picty__container:not(.feetDetails__pict_container) > div {
    width: 100px;
    min-width: unset;
  }
  .mediaContainer > .viewFeetModelsProfiles__photos-content {
    width: calc(33% - 8px);
    min-width: auto;
  }
  /* SEARCH */
  .searchDefaultView__inputContainer {
    width: calc(100% - 32px);
    padding: 16px;
    margin: 0 auto;
  }
  #searchIcon {
    width: 22px;
    top: 12px;
  }
  .searchInput__wrapper input {
    height: 50px !important;
  }
  #settingSearch {
    height: 28px;
  }
  .searchDefaultView__picture {
    max-width: calc((100% / 3) - 4px);
    /* min-width: unset; */
    min-width: 116px;
  }
  /* Subscription */
  .user_list > div.user_thumbnail_details {
    margin: 8px;
  }
  .user_thumbnail_details .avatar {
    width: 40px;
    height: 40px;
  }
  .user_thumbnail_details .user_details {
    width: calc(100% - 50px);
  }
  .user_thumbnail_details h2 {
    font-size: 0.9rem;
  }
  .subscriptionLoverCard__date {
    font-size: 0.9rem !important;
  }
  .user_thumbnail_details p,
  .subscriptionLover__prices {
    font-size: 0.8rem !important;
  }
  .subscriptionLover__prices {
    min-width: 100px;
  }
  /* Messages */
  .allMessages__CardNoReaded {
    padding: 16px 0;
  }
  .allMessages__Date {
    top: 8px;
    font-size: 10px;
  }
  .Background {
    top: calc(50% - 15px);
    width: 30px;
    height: 30px;
  }
  .createMessage__top > button {
    width: 150px !important;
  }
  /* Messages */
  .createMessage__contentMessage {
    height: calc(100vh - 345px);
  }
  /*  Model Notifications  */
  .notificationsModels.user_list > .user_thumbnail_details {
    max-width: calc(100% - 66px);
  }
  .notificationsModels.user_list > .user_thumbnail_details > sub {
    font-size: 0.7rem;
    top: 12px;
    right: -56px;
  }
  /* Followers/Followings */
  .followingList__Button.pink__button {
    font-size: 0.8rem !important;
    min-height: 30px;
    height: 30px;
    padding: 4px 12px;
  }
  /* Buy Tickets*/
  .buyTicklesCredit__buttons {
    margin-top: 24px;
    flex-direction: column;
    gap: 0;
  }
  .buyTicklesCredit__buttons > button {
    width: 100%;
  }
  .buyTicklesCredit__cards {
    margin-top: 24px;
  }
  .BlurnoFree {
    left: 36%;
  }
  .postPublicationPictylist__button {
    left: 5%;
  }
}
@media only screen and (max-width: 480px) {
  .ViewOwnProfileModelsTop__picture > img {
    width: 80px;
    height: 80px;
    margin-right: 12px;
  }
  .ViewOwnProfileModelsTop__text {
    max-width: 220px;
  }
  .ViewOwnProfileContentBloc__text > h4 {
    font-size: 18px !important;
  }
  .ViewOwnProfileModelsTop__text > b,
  .ViewOwnProfileContentBlocDescription__text {
    font-size: 12px !important;
  }
  .ViewOwnProfileContentBloc__text > img {
    width: 20px !important;
    height: 20px !important;
  }
  .ViewOwnProfileModels__buttonsTopBloc > button {
    margin-top: 8px !important;
    font-size: 0.7rem !important;
    padding-left: 6px;
    padding-right: 6px;
  }
  .picty__container:not(.feetDetails__pict_container) > div {
    width: calc(33% - 8px);
  }
  .picty__container p {
    /* font-size: .8rem; */
    margin: 4px;
  }

  .picty__container span {
    font-size: 0.7rem;
    margin: 4px;
  }

  .picty__container > div {
    margin: 4px;
  }
  .followList__Button.pink__button {
    font-size: 0.8rem !important;
    min-height: 32px;
    height: 32px;
    padding: 4px 22px;
  }

  .followList__Button.white__button {
    font-size: 0.8rem !important;
    min-height: 32px;
    height: 32px;
    padding: 4px 16px;
    margin-top: 0 !important;
  }

  .followList__Button.darkred__button {
    font-size: 0.8rem !important;
    min-height: 32px;
    height: 32px;
    padding: 4px 16px;
  }
}
@media only screen and (max-width: 380px) {
  .change-feet-model-subscription-amount-content-container
    .epoch-subscription-product-lists {
    gap: 0px !important;
  }

  .change-feet-model-subscription-amount-content-container
    .epoch-subscription-product-lists
    .epoch-subscription-amount-wrapper {
    width: 95px !important;
  }

  .change-feet-model-subscription-amount-content-container
    .epoch-subscription-product-lists
    .epoch-subscription-product-wrapper
    .popular-text-container {
    width: 100px !important;
  }
}

@media only screen and (max-width: 320px) {
  .change-feet-model-subscription-amount-content-container
    .epoch-subscription-product-lists
    .epoch-subscription-amount-wrapper {
    width: 85px !important;
    height: 115px;
  }

  .change-feet-model-subscription-amount-content-container
    .epoch-subscription-product-lists
    .epoch-subscription-product-wrapper
    .popular-text-container {
    width: 90px !important;
  }
}

@media screen and (min-width: 1240px) {
  #root {
    background: #e7e7e7;
  }
}
