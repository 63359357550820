.SubscribeToUnlockPublication__paper {
  position: absolute;
  width: 100%;
  max-width: 300px;
  height: auto;
  background-color: white;
  /* box-shadow: 2px 2px 6px black; */
  padding: 10px 17px 37px;
}

.modal-Subscribe {
  border-radius: 7px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
}

.modal-Subscribe:focus {
  outline: none !important;
}

.SubscribeToUnlockPublication__content {
  width: 101%;
  margin-left: -17px;
  margin-top: -25px;
}

.SubscribeToUnlockPublication__close {
  text-align: right;
}

.SubscribeToUnlockPublication__close img {
  width: 40px;
  position: relative;
  right: -35px;
}

.SubscribeToUnlockPublication__header {
  background-image: linear-gradient(
    to right,
    rgba(255, 39, 117, 1),
    rgba(255, 87, 104, 1)
  );
  border-radius: 7px 7px 0 0;
  height: 68px;
  width: 112.7%;
  margin-top: -26px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: -9px;
}

.SubscribeToUnlockPublication__header > img {
  width: 36px;
}

.SubscribeToUnlockPublication__header > h2 {
  color: white;
  font-family: "Roboto";
  font-size: 17px;
  margin-left: 51px;
}

.SubscribeToUnlockPublication__body {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: inline-block;
  margin-left: 5px;
}

.SubscribeToUnlockPublication__top h4 {
  margin-top: 0 !important;
}

.SubscribeToUnlockPublication__top > p,
.SubscribeToUnlockPublication__center > p,
.SubscribeToUnlockPublication__bottom > p {
  color: #323232 !important;
  text-shadow: inherit;
  font-size: 16px;
}

.SubscribeToUnlockPublication__bottom > h3 {
  font-weight: 600;
  font-size: 16px;
  color: #ff2775;
  margin-top: 33px;
  margin-bottom: -12px;
}

/* .SubscribeToUnlockPublication__bottom {
  width: 117%;
  margin-left: -12px;
} */

.SubscribeToUnlockPublication__center-button {
  margin-bottom: -30px !important;
}

/* .SubscribeToUnlockPublication__center {
  margin-top: 45px;
} */

.SubscribeToUnlockPublication__center-p {
  margin-top: -12px;
}

.SubscribeToUnlockPublication__pict {
  width: 73.94px;
  height: 73.94px;
  border-radius: 50%;
  object-fit: cover;
}

.SubscribeToUnlockPublication__imgContainer {
  position: relative;
}

.SubscribeToUnlockPublication__pictyPict {
  position: absolute;
  right: 90px;
  margin-top: -3px;
  width: 34.55px;
  height: 34.55px;
}

.SubscribeToUnlockPublication__name {
  margin-top: 0 !important;
}

.SubscribeToUnlockPublication__name > h4 {
  color: #ff2775;
  font-size: 19px;
  margin: 10px 0 !important;
}

.SubscribeToUnlockPublication__button {
  background: #f7f7f7;
  width: 100%;
}

.SubscribeToUnlockPublication__button > .MuiButton-label {
  background: #ff5768;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
}

.SubscribeToUnlockPublication__button > span > h4 {
  color: #fff !important;
  font-size: 16px;
  text-transform: lowercase;
  margin: 10px 0;
}

.SubscribeToUnlockPublication__UserView .subscription-user-profile-view {
  color: #fff !important;
  font-weight: 500 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px !important;
  text-transform: initial !important;
}

.SubscribeToUnlockPublication__button > span > p {
  color: #fff !important;
  text-shadow: none;
  font-size: 15px;
  text-transform: initial;
  margin-top: -15px !important;
  margin-bottom: 10px !important;
}

.subscription-desc {
  list-style: none;
  padding-left: 0 !important;
  text-align: left;
}

.subscription-desc li {
  display: inline-flex;
  align-items: baseline;
  padding: 8px 0px 8px 8px;
}

.subscription-desc li img {
  padding-right: 12px;
}
