.ticklesLover__container {
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  padding-bottom: 60px;
}

.back__button {
  position: absolute;
}

.ticklesLover__header {
  display: flex;
  align-items: center;
  background: #f7f7f7;
  box-shadow: 0 2px 40px #0000000d;
  margin-top: 0%;
  height: 75px;
}

.ticklesLover__header > svg {
}

.ticklesLover__header > h4 {
  margin: auto;
  padding-right: 20px;
}

.ticklesLover__top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #f7f7f7;
  margin: 8px auto 15px;
}

.ticklesLover__img {
  width: 48px;
  margin-right: 15px;
  margin-left: 35px;
}

.ticklesLoverTop__text > h2 {
  color: #000000;
  margin: 23px auto -21px;
  font-size: 25px;
}

.ticklesLoverTop__text > p {
  color: #fe294e;
  text-shadow: none;
  font-size: 16px;
}

.ticklesLover__button__container {
  width: 25%;
  display: flex;
  margin: auto;
}

.ticklesLover__button {
  background-image: linear-gradient(
    to right,
    rgb(248, 46, 115),
    rgb(249, 80, 107)
  );
  color: white !important;
  text-transform: capitalize !important;
  width: 100%;
  box-shadow: 0 3px 6px #00000029 !important;
}

.ticklesLover__content {
  width: 50%;
  margin: auto;
  padding-top: 25px;
}

.ticklesLover__content > h4 {
  font-size: 17px;
  font-weight: 500;
}

.ticklesLover__modelList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.ticklesLover__modelList > img {
  width: 50px;
  border-radius: 50%;
  border: 1px solid #fe294e;
}

.ticklesLover__modelListText {
  margin-left: 15px;
}

.ticklesLover__modelListText > h4 {
  font-size: 15px;
  margin-bottom: -10px;
  margin-top: -2px;
}

.ticklesLover__modelListText > sub {
  font-size: 12px;
  color: #646464;
}

@media screen and (max-width: 414px) {
  .ticklesLover__content {
    width: 85%;
    margin: auto;
    padding-top: 25px;
  }

  .ticklesLover__button__container {
    width: 80%;
    margin: auto;
  }
}
