.commentSection__container {
  height: calc(100vh - 68px);
}

.commentBubbles {
  color: #646464 !important;
  background: #ffffff;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 12px;
  position: relative;
  width: 100%;
}

.commentBubbleTop {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.commentBubbleMessContent > p {
  color: #646464 !important;
  text-shadow: none;
}

.commentBubbleTop > b,
.commentBubbleTop > p {
  color: #646464 !important;
  text-shadow: none;
  cursor: pointer;
}
.commentBubbleTop > b {
  margin: 0 10px;
}
.commentBubbleTop > p {
  position: absolute;
  right: 10px;
  font-size: 0.8rem;
}
.commentBubbleTop > .MuiAvatar-circle {
  margin-right: 12px;
  width: 25px;
  height: 25px;
}

.commentsSection__innerContent {
  margin-top: 55px;
}

.swipe-action {
  display: flex;
  width: 100%;
  /* align-items: stretch; */
  align-items: center !important;
  justify-content: center !important;
}

.swipe-action > * {
  width: 50% !important;
  flex: 0 !important;
}

.swipe-action img {
  max-width: 40px;
  width: 100% !important;
}

.swipeable-list {
  height: fit-content !important;
}

.subscriberName > b {
  color: #ff2775 !important;
  text-shadow: none;
}
