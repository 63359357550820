.notificationsModels__container {
  width: 100%;
}
.notificationsModels__noNotif {
  text-align: center;
  margin-bottom: 16px;
}

.notificationsModelsContent__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.notificationsModels.user_list > .user_thumbnail_details {
  width: 100%;
  position: relative;
  max-width: calc(100% - 72px);
}
.notificationsModels.user_list > .user_thumbnail_details > sub {
  position: absolute;
  right: -64px;
  top: 22px;
}
.notificationsModelsContentSection__likes,
.notificationsModelsContentSection__comments,
.notificationsModelsContentSection__tickles,
.notificationsModelsContentSection__subscritions {
  width: 100%;
}

/* .notificationsModelsSectionLikes__head,
.notificationsModelsSectionComments__head,
.notificationsModelsSectionTickles__head,
.notificationsModelsSectionSubscriptions__head {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  padding: 15px !important;
  margin-top: 15px;
} */
.notificationsModelsSectionLikes__head,
.notificationsModelsSectionComments__head,
.notificationsModelsSectionTickles__head,
.notificationsModelsSectionSubscriptions__head {
  width: 100%;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
}

.hr-sep-notifications {
  width: 100%;
  opacity: 0.2;
}
.likesNotificationsTopChevron {
  margin-left: 72%;
}

.notificationsModelsSectionLikes__head > b,
.notificationsModelsSectionComments__head > b,
.notificationsModelsSectionTickles__head > b,
.notificationsModelsSectionSubscriptions__head > b {
  font-size: 15px;
}

.notificationsModels__Icon {
  width: 22px;
  margin-right: 15px;
}

.notificationsModels__likesList,
.notificationsModels__ticklesList,
.notificationsModels__commentsList,
.notificationsModels__subscritionsList {
  /* background: #F7F7F7 padding-box; */
  /* min-height: 88px; */
  background-color: #ffffff;
  box-shadow: 0 30px 60px #8a959e33;
  border-radius: 7px;
  opacity: 1;
  text-align: left;
  padding: 12px;
  cursor: pointer;
}

.notClickedBackground {
  background: #ffffff padding-box;
  flex-direction: column;
}
.notificationsModelsContent__section > div {
  margin-top: 8px;
  margin-bottom: 8px;
}
.clickedBackground {
  background: #f7f7f7 padding-box;
  flex-direction: column;
}
.hidden-body > .hr-sep-notifications,
.hidden-body > .notification-body {
  display: none;
}
.notification-body {
  max-height: 560px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.notifications__footer {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: contents;
}

.notifications__footer > button {
  margin-left: -36px;
}

.notSwipped {
  max-height: 56px;
  overflow: hidden;
}

.swipped {
  max-height: inherit;
  overflow: visible;
}

.margin-swipped {
  margin-top: -29px;
}
.notif-title > * {
  display: inline-block;
  vertical-align: middle;
}
.notif-title > b {
  margin-bottom: -2px;
}
.notificationsModelsContent__section {
  max-width: 500px;
  margin: 0 auto;
}
.notificationsModelsSection__head {
  position: relative;
  padding: 12px;
  width: calc(100% - 24px);
}
.notificationsModelsSection__head > svg {
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 10px;
}
.notificationsNotDeployed {
  height: 45px !important;
  overflow: hidden !important;
}

.marginNone {
  margin-top: 0 !important;
}
