.selected-filters,
.searchDefaultView__inputContainer {
  max-width: 700px;
}
.selected-filters {
  width: calc(100% - 16px);
  padding: 0 8px;
  margin: 0 auto;
}
.searchDefaultView__inputContainer {
  display: flex;
  align-items: center;
  position: sticky;
  top: 50px;
  z-index: 99;
  justify-content: center;
  background-color: #f3f3f3;
  margin: 32px auto;
  padding: 24px;
}

.serachDefaultView__pictureContent {
  max-width: 2000px;
  margin: 0 auto;
  padding: 16px 0;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  text-align: left !important;
}

.serachDefaultView__pictureContent .user_thumbnail_details,
.serachDefaultView__pictureContent .pagination-loader,
.serachDefaultView__pictureContent .no-data-text {
  margin: 10px 15px !important;
}

.searchDefaultView__selectedFilters {
  max-width: 800px;
  margin: 0 auto;
  position: sticky;
  top: 130px;
  z-index: 99;
  background-color: #f7f7f7;
}
.searchDefaultView__selectedFilters .picty__container > div {
  max-width: 120px;
  min-width: unset;
}
.searchDefaultView__picture {
  max-width: calc(100% / 15);
  min-width: 130px;
  max-height: 130px;
  padding: 1px;
  flex: 1 1 auto;
}
.searchDefaultView__picture > span {
  display: flex !important;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.searchDefaultViewPicture__img {
  width: 100%;
  object-fit: cover;
  height: 125px;
}

#settingSearch {
  height: 32px;
}
