.feet-model-guides-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.feet-model-guides-container > p {
  padding-left: 42px;
  padding-right: 42px;
  font-weight: 400;
  line-height: 30.2px;
  font-size: clamp(18px, 4vw, 24px);
}
