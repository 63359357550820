.loader-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 52vmin;
  pointer-events: none;
  border-radius: 50%;
  /* background-image: linear-gradient(156deg, #fccbd2 0%, #fcc6d3 38%, #fcc0d4 55%); */
  width: 52vmin;
  /* z-index: 9999; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.logo-picture {
  width: 25vmin;
  position: relative;
  z-index: 9999;
  height: 34vmin;
}

#loader {
  animation: spin 1s linear infinite;
  height: 39vmin;
  width: 39vmin;
  position: absolute;
  border-radius: 50%;
  margin: 5px;
  opacity: 0.11;
  filter: blur(10px);
}

/* Loader with box-shadow only */

@keyframes spin {
  0% {
    box-shadow: 0px -30px rgb(58, 57, 57), 10px -30px rgb(73, 72, 72),
      20px -20px #000, 30px -10px #000, 30px 0px #000, 30px 10px #000,
      20px 20px #000, 10px 30px #000, 0px 30px transparent,
      -10px 30px transparent, -20px 20px transparent, -30px 10px transparent,
      -30px 0px transparent, -30px -10px transparent, -20px -20px transparent,
      -10px -30px transparent;
  }

  6.25% {
    box-shadow: 0px -30px transparent, 10px -30px #000, 20px -20px #000,
      30px -10px #000, 30px 0px #000, 30px 10px #000, 20px 20px #000,
      10px 30px #000, 0px 30px #000, -10px 30px transparent,
      -20px 20px transparent, -30px 10px transparent, -30px 0px transparent,
      -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
  }

  12.5% {
    box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px #000,
      30px -10px #000, 30px 0px #000, 30px 10px #000, 20px 20px #000,
      10px 30px #000, 0px 30px #000, -10px 30px #000, -20px 20px transparent,
      -30px 10px transparent, -30px 0px transparent, -30px -10px transparent,
      -20px -20px transparent, -10px -30px transparent;
  }

  18.75% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px transparent, 30px -10px #000, 30px 0px #000, 30px 10px #000,
      20px 20px #000, 10px 30px #000, 0px 30px #000, -10px 30px #000,
      -20px 20px #000, -30px 10px transparent, -30px 0px transparent,
      -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
  }

  25% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px transparent, 30px -10px transparent, 30px 0px #000,
      30px 10px #000, 20px 20px #000, 10px 30px #000, 0px 30px #000,
      -10px 30px #000, -20px 20px #000, -30px 10px #000, -30px 0px transparent,
      -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
  }

  31.25% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px transparent, 30px -10px transparent, 30px 0px transparent,
      30px 10px #000, 20px 20px #000, 10px 30px #000, 0px 30px #000,
      -10px 30px #000, -20px 20px #000, -30px 10px #000, -30px 0px #000,
      -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
  }

  37.5% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px transparent, 30px -10px transparent, 30px 0px transparent,
      30px 10px transparent, 20px 20px #000, 10px 30px #000, 0px 30px #000,
      -10px 30px #000, -20px 20px #000, -30px 10px #000, -30px 0px #000,
      -30px -10px #000, -20px -20px transparent, -10px -30px transparent;
  }

  43.75% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px transparent, 30px -10px transparent, 30px 0px transparent,
      30px 10px transparent, 20px 20px transparent, 10px 30px #000,
      0px 30px #000, -10px 30px #000, -20px 20px #000, -30px 10px #000,
      -30px 0px #000, -30px -10px #000, -20px -20px #000,
      -10px -30px transparent;
  }

  50% {
    box-shadow: 0px -30px transparent, 10px -30px transparent,
      20px -20px transparent, 30px -10px transparent, 30px 0px transparent,
      30px 10px transparent, 20px 20px transparent, 10px 30px transparent,
      0px 30px #000, -10px 30px #000, -20px 20px #000, -30px 10px #000,
      -30px 0px #000, -30px -10px #000, -20px -20px #000, -10px -30px #000;
  }

  56.25% {
    box-shadow: 0px -30px #000, 10px -30px transparent, 20px -20px transparent,
      30px -10px transparent, 30px 0px transparent, 30px 10px transparent,
      20px 20px transparent, 10px 30px transparent, 0px 30px transparent,
      -10px 30px #000, -20px 20px #000, -30px 10px #000, -30px 0px #000,
      -30px -10px #000, -20px -20px #000, -10px -30px #000;
  }

  62.5% {
    box-shadow: 0px -30px #000, 10px -30px #000, 20px -20px transparent,
      30px -10px transparent, 30px 0px transparent, 30px 10px transparent,
      20px 20px transparent, 10px 30px transparent, 0px 30px transparent,
      -10px 30px transparent, -20px 20px #000, -30px 10px #000, -30px 0px #000,
      -30px -10px #000, -20px -20px #000, -10px -30px #000;
  }

  68.75% {
    box-shadow: 0px -30px #000, 10px -30px #000, 20px -20px #000,
      30px -10px transparent, 30px 0px transparent, 30px 10px transparent,
      20px 20px transparent, 10px 30px transparent, 0px 30px transparent,
      -10px 30px transparent, -20px 20px transparent, -30px 10px #000,
      -30px 0px #000, -30px -10px #000, -20px -20px #000, -10px -30px #000;
  }

  75% {
    box-shadow: 0px -30px #000, 10px -30px #000, 20px -20px #000,
      30px -10px #000, 30px 0px transparent, 30px 10px transparent,
      20px 20px transparent, 10px 30px transparent, 0px 30px transparent,
      -10px 30px transparent, -20px 20px transparent, -30px 10px transparent,
      -30px 0px #000, -30px -10px #000, -20px -20px #000, -10px -30px #000;
  }

  81.25% {
    box-shadow: 0px -30px #000, 10px -30px #000, 20px -20px #000,
      30px -10px #000, 30px 0px #000, 30px 10px transparent,
      20px 20px transparent, 10px 30px transparent, 0px 30px transparent,
      -10px 30px transparent, -20px 20px transparent, -30px 10px transparent,
      -30px 0px transparent, -30px -10px #000, -20px -20px #000,
      -10px -30px #000;
  }

  87.5% {
    box-shadow: 0px -30px #000, 10px -30px #000, 20px -20px #000,
      30px -10px #000, 30px 0px #000, 30px 10px #000, 20px 20px transparent,
      10px 30px transparent, 0px 30px transparent, -10px 30px transparent,
      -20px 20px transparent, -30px 10px transparent, -30px 0px transparent,
      -30px -10px transparent, -20px -20px #000, -10px -30px #000;
  }

  93.75% {
    box-shadow: 0px -30px #000, 10px -30px #000, 20px -20px #000,
      30px -10px #000, 30px 0px #000, 30px 10px #000, 20px 20px #000,
      10px 30px transparent, 0px 30px transparent, -10px 30px transparent,
      -20px 20px transparent, -30px 10px transparent, -30px 0px transparent,
      -30px -10px transparent, -20px -20px transparent, -10px -30px #000;
  }

  100% {
    box-shadow: 0px -30px #000, 10px -30px #000, 20px -20px #000,
      30px -10px #000, 30px 0px #000, 30px 10px #000, 20px 20px #000,
      10px 30px #000, 0px 30px transparent, -10px 30px transparent,
      -20px 20px transparent, -30px 10px transparent, -30px 0px transparent,
      -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
  }
}
