.SubscribeToUnlockPublication__paper {
  position: absolute;
  width: 268px;
  height: 302px;
  background-color: white;
  /* box-shadow: 2px 2px 6px black; */
  padding: 10px 17px 37px;
}

.modal-Subscribe {
  border-radius: 7px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
}

.modal-Subscribe:focus {
  outline: none !important;
}

.SubscribeToUnlockPublication__content {
  width: 101%;
  margin-left: -17px;
  margin-top: -25px;
}

.SubscribeToUnlockPublication__header {
  background-image: linear-gradient(
    to right,
    rgba(255, 39, 117, 1),
    rgba(255, 87, 104, 1)
  );
  border-radius: 7px 7px 0 0;
  height: 68px;
  width: 112.7%;
  margin-top: -26px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: -9px;
}

.SubscribeToUnlockPublication__header > img {
  width: 36px;
}

.SubscribeToUnlockPublication__header > h2 {
  color: white;
  font-family: "Roboto";
  font-size: 17px;
  margin-left: 51px;
}

.SubscribeToUnlockPublication__body {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: inline-block;
  margin-left: 5px;
}

.SubscribeToUnlockPublication__top > p,
.SubscribeToUnlockPublication__center > p,
.SubscribeToUnlockPublication__bottom > p {
  color: #323232 !important;
  text-shadow: inherit;
  font-size: 16px;
}

.SubscribeToUnlockPublication__bottom > h3 {
  font-weight: 600;
  font-size: 16px;
  color: #ff2775;
  margin-top: 33px;
  margin-bottom: -12px;
}

/* .SubscribeToUnlockPublication__bottom {
  width: 117%;
  margin-left: -12px;
} */

.SubscribeToUnlockPublication__center-button {
  margin-bottom: -36px !important;
}

/* .SubscribeToUnlockPublication__center {
  margin-top: 45px;
} */

.SubscribeToUnlockPublication__center-p {
  margin-top: -12px;
}

.SubscribeToUnlockPublication__pict {
  width: 73.94px;
  height: 73.94px;
  border-radius: 50%;
  object-fit: cover;
}

.SubscribeToUnlockPublication__imgContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.SubscribeToUnlockPublication__pictyPict {
  position: absolute;
  right: 90px;
  margin-top: -3px;
  width: 34.55px;
  height: 34.55px;
}

.SubscribeToUnlockPublication__name {
  margin-top: -18px;
  display: flex;
  justify-content: center;
}

.SubscribeToUnlockPublication__name > h4 {
  color: #ff2775;
  font-size: 19px;
}

.SubscribeToUnlockPublication__button {
  background: #f7f7f7;
  width: 100%;
}

.SubscribeToUnlockPublication__button > .MuiButton-label {
  background: #ff7e9d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
}

.SubscribeToUnlockPublication__button > span > h4 {
  color: #f7f7f7 !important;
  font-size: 16px;
  text-transform: lowercase;
  margin-top: 14px;
}

.SubscribeToUnlockPublication__button > span > p {
  color: #f7f7f7;
  text-shadow: none;
  font-size: 15px;
  text-transform: initial;
  margin-top: -27px;
}
