.feet-model-container-guides {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: justify !important;
}

.feet-model-container-guides > p {
  padding-left: 42px;
  padding-right: 42px;
  font-weight: 400;
  line-height: 30.2px;
}

.i-have-agreed {
  display: flex;
  align-items: center;
  /* font-size: clamp(15px, 4vw, 18px); */
}

.i-have-agreed > span {
  font-size: clamp(15px, 4vw, 18px);
  line-height: 21px;
}
