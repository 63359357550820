/*
.video-container {
  max-height: 800px;
  background-color: black;
  padding-top: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 3%;
} */
.video-container {
  width: 100% !important;
  height: unset !important;
  min-height: 100%;
  padding-bottom: 0 !important;
}
.video-container > video {
  margin-top: 0 !important;
  max-width: 100%;
  max-height: 100%;

  object-fit: cover;
  min-height: 126px !important;
  max-height: 126px;
}

.video-container.blur-video {
  -webkit-filter: blur(20px);
  filter: blur(20px);
}
