.searchHistory__content {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.searchHistory__button {
  /* position: absolute!important; */
  width: 100%;
  margin-bottom: 10px !important;
}
