.homePageFr__stickers > div > sub {
  font-size: 11px;
  margin-left: -9px;
}

.publication__page_header {
  margin-top: 70px;
}
.publication__page__img > span,
.publication__page__img img {
  object-fit: contain;
  /* aspect-ratio: 1/1; */
}

.tickle_icon {
  width: 30px !important;
}
