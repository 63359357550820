.cropFromFile-footer {
  width: 100%;
}

.cropFromFile-header {
  position: fixed;
  top: 0;
  width: 100%;
}

.cropFromFile-content {
  height: 100%;
  width: 100%;
}
.CropFromFile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cropFromFile-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.text-step-cropFromFile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cropFromFile-h4 {
  margin-bottom: -8%;
}

.text-picture-step-cropFr {
  font-size: 15px;
}

.cropFromFile-img-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
}

.cropFromFile-img {
  margin-top: -32%;
}

.col-6 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-fuild {
  width: 97%;
}

.img-fuild-filters {
  max-width: 130px;
}

.selected {
  filter: blur(2px) contrast(53%) sepia(70%) !important;
}

.img-fuild-filters:active {
  filter: drop-shadow(2px 4px 7px black);
  margin-top: -5px;
}

/* .img-fuild-filters:focus {
  outline: mediumorchid;
} */

.rec.rec-arrow.rec.rec-arrow-left,
.rec.rec-arrow.rec.rec-arrow-right {
  display: none !important;
}

.col-6-filters {
  width: 100%;
  margin-top: 15px;
}

.rec.rec-carousel-item {
  margin: 0 8px;
}
.rec-item-wrapper {
  width: max-content !important;
}
.rec.rec-pagination {
  display: none !important;
}
