.helpLover__content {
  display: table;
}
.helpLover__content form {
  display: table-cell;
  vertical-align: middle;
}
.helpLover__content form h4 {
  margin: 12px 0;
}
.helpLover__textarea {
  min-height: 300px;
  padding: 16px;
  width: calc(100% - 32px);
}
.helpLoverBottom__button {
  width: 100%;
}
