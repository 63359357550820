.RequestCM__header {
  /*background-image: linear-gradient(
      to right,
      rgba(255, 39, 117, 1),
      rgba(255, 87, 104, 1)
    );*/
  border-radius: 7px 7px 0 0;
  height: 68px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RequestCM__header > h6 > img#message-icon {
  position: relative;
  left: 20px;
  width: 70px;
  height: 50px;
}

.RequestCM__header > h6 > h2#request-cm-has-tickles {
  color: black;
  font-family: "Roboto", serif;
  font-size: 17px;
}

.RequestCM__header > h6.MuiTypography-h6 {
  display: flex;
  align-items: center;
  justify-content: center;
}
