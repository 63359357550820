.Messages__container {
  width: 100%;
}
.messageTextSection {
  /* width: 148px; */
}
.message__tabPanel > div {
  padding: 0;
}
.PrivateTabIndicator-colorSecondary-13 {
  background-color: #f50057;
  width: 50% !important;
}

.messagesContainer__title {
  color: #636363;
  font-size: 20px;
}

.messagesContainer__create {
  color: #f72c3f !important;
  font-size: 16px;
  text-transform: capitalize;
}
.tabChoicePaper__root {
  background-color: #f7f7f7 !important;
  box-shadow: none !important;
}
.Messages__tabChoice .tabChoicePaper__root {
  margin-top: 0 !important;
  border-radius: 0 !important;
}
#simple-tab-0 {
  width: 50% !important;
}

#simple-tab-1 {
  width: 50% !important;
}

.MuiTab-wrapper {
  font-size: 16px;
  text-transform: capitalize;
}

.MuiTab-textColorInherit.Mui-selected > span {
  font-size: 18px;
}

.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  color: #000000 !important;
  font-weight: 900 !important;
}

.MuiTab-textColorInheri {
  color: #b3b3b3;
}

#simple-tabpanel-0 {
  padding-bottom: 50px;
}

#simple-tabpanel-1 {
  padding-bottom: 50px;
  min-height: 750px;
}

.messages-tabs .MuiTab-wrapper {
  font-size: 14px !important;
}

.Messages__tabChoice .sticky-tab {
  position: sticky;
  top: 49.5px;
  z-index: 99998;
}
