.feetloverGuides__content > p {
  color: #373633 !important;
}

.feetloverGuides__content > span {
  display: block;
  color: #373633 !important;
}

.feet-lover-guides-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: justify !important;
}

.feet-lover-guides-container > p {
  padding-left: 42px;
  padding-right: 42px;
  font-weight: 400;
  line-height: 30.2px;
}
