.notFound__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f7f7f7;
  height: 100vh;
  width: 100%;
}

.notFound__img {
  max-width: 67%;
}

.color-change-2x {
  animation: color-change-2x 2s linear infinite alternate both;
}

@keyframes color-change-2x {
  0% {
    background: #e0dcdc;
  }

  100% {
    background: #f7f7f7;
  }
}

.swirl-in-fwd {
  animation: swirl-in-fwd 0.6s ease-out both;
}

@keyframes swirl-in-fwd {
  0% {
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }

  100% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}

.slide-in-top {
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.scale-out-center {
  animation: scale-out-center 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes scale-out-center {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 1;
  }
}

.tracking-out-expand-fwd {
  animation: tracking-out-expand-fwd 0.8s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
}

@keyframes tracking-out-expand-fwd {
  0% {
    transform: translateZ(0);
    opacity: 1;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    letter-spacing: 1em;
    transform: translateZ(300px);
    opacity: 0;
  }
}

.puff-in-center {
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

@keyframes puff-in-center {
  0% {
    transform: scale(2);
    filter: blur(4px);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    filter: blur(0px);
    opacity: 1;
  }
}

.notFound__text > p {
  color: #64625d;
  text-shadow: none;
  font-weight: 600;
}

.notFound__button {
  background-image: linear-gradient(
    to right,
    rgb(248, 46, 115),
    rgb(249, 80, 107)
  );
  color: white !important;
  text-transform: capitalize !important;
  font-weight: 700 !important;
  border-radius: 22px !important;
  min-width: 157px !important;
}

.slit-out-vertical {
  animation: slit-out-vertical 0.5s ease-in both;
}

@keyframes slit-out-vertical {
  0% {
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }

  54% {
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }

  100% {
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
}
