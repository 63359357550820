.viewFeetModelsProfiles__container {
  width: 100%;
  min-height: calc(100vh - 64px);
}

.viewFeetModelsProfiles__IconButton {
  position: absolute !important;
  right: 23px;
}

.user_profile_image {
  margin-right: 24px;
  position: relative;
  width: 120px;
  height: 120px;
}
.best-icon {
  position: absolute;
  width: 36px;
  left: unset !important;
  right: -4px !important;
  top: 0 !important;
}

.user_profile_image > img.profile_image {
  border-radius: 50%;
  width: 100%;
}
.viewFeetModelsProfilesTop__picture {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7%;
  position: relative;
  padding: 0 8px;
}

.viewFeetModelsProfiles__content {
  padding: 0;
  margin: 0 auto;
}
.viewFeetModelsProfiles__bloc {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000040;
  margin-top: 6%;
  padding: 24px;
  overflow: hidden;
}

.viewFeetModelsProfilesToggled__bloc {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000040;
  margin-top: 6%;
  padding: 10px;
}

.viewFeetModelsProfilesTopText__description {
  margin-top: 24px;
  text-align: center;
  margin-bottom: 10px;
  display: none;
  /* position: relative; */
}

.viewFeetModelsProfilesTopText__bottom {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  /* position: absolute; */
}

.viewFeetModelsProfilesTopText__bottom > button {
  margin-top: 12px;
  display: none;
}

.viewFeetModelsProfilesTopTextToggled__bottom {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
}

.viewModelsProfiles__buttonsTopBloc {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  max-width: 500px;
  margin: 20px auto 0;
  padding: 0 8px;
}
.viewModelsProfiles__buttonsTopBloc > button {
  width: 50%;
}
.viewFeetModelsProfiles__buttonBottomBloc {
  width: 95%;
  margin: 0 auto;
  padding: 15px 8px;
}
.viewFeetModelsProfilesContentTop__bloc .makeStyles-root-2 {
  align-items: center !important;
  justify-content: center !important;
}
.subscribe-button.pink__button {
  width: 100%;
  margin-top: 0 !important;
}

.viewModelsProflies__photoContainer {
  width: 128%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -43px;
  margin-top: 32px;
}
.viewFeetModelsProfiles__pictures > img.img-profile-pict {
  width: 100%;
  /* height: 126px;
  object-fit: cover;
  object-position: center; */
  background-color: red;
}

.img-profile-pict {
  object-fit: cover;
  height: 126px;
}

.profileMediaIcons {
  position: absolute;
  display: flex;
  align-items: center;
  top: 2px;
  right: 2px;
  z-index: 999;
}

.view_own_profile_publication_moderation_banner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  background-color: #29221e;
  padding: 5px 0;
}

.view_own_profile_publication_moderation_banner > p {
  margin: 0;
  text-align: center;
  font-size: 0.6rem !important;
  color: #fff;
}

.viewFeetModelsProfiles__icon-absol.image {
  display: none;
  width: 16px;
}
.viewFeetModelsProfiles__icon-absol.video {
  /*display: none;*/
  width: 25px;
  top: 2px;
  position: relative;
}
.viewFeetModelsProfiles__icon-absol.star {
  width: 20px;
}
.noFree {
  /*position: absolute;*/
  z-index: 6666;
}
.non-subscriber.noFree {
  filter: blur(20px);
}

.blur {
  filter: blur(8px);
}

.BlurnoFree {
  width: 38px;
  position: absolute;
  left: 42%;
  top: 38%;
  z-index: 666;
  image-rendering: pixelated;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  filter: drop-shadow(3px 5px 3px rgb(0 0 0 / 0.4));
}

.viewFeetModelsProfiles__pictures {
  display: flex;
  align-items: center;
  /* align-items: stretch; */
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 100px;
  background-color: #dddddd;
}
.viewFeetModelsProfiles__pictures > div {
  width: 100%;
}

.viewFeetModelsProfiles__pictures > .private-icons-non-subscriber {
  width: initial;
}

#all-tabpanel-0,
#all-tabpanel-1 {
  width: 100%;
  padding: 0 8px;
}

.panel-box {
  width: calc(100% + 10px);
  max-width: 2000px;
  margin: 0 -5px;
  padding: 15px 0 !important;
}

.mediaContainer {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: center;
  flex-wrap: wrap;
}
.mediaContainer .lazy-load-image-background.blur.lazy-load-image-loaded > img {
  display: block;
}
.mediaContainer > .viewFeetModelsProfiles__photos-content {
  position: relative;
  max-width: 228px;
  max-height: 228px;
  min-width: 134px;
  width: calc(20% - 10px);
  margin: 1px;
  flex: 1 1 auto;
  /* to have a uniform display of video and image, need to set a fixed height; */
  height: 126px;
}
.viewFeetModelsProfiles__buttonBottomBloc .mediaContainer {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: center;
  padding: 8px 4px;
}
.viewFeetModelsProfilesTop__picture > img:first-child {
  width: 24px !important;
  top: 36px !important;
  margin-left: 28%;
}

.video-container-publication-page {
  display: flex;
  align-items: center;
}

.normalize-text,
.normalize-text * {
  text-transform: none !important;
}

.w100 {
  width: 100%;
}

.viewFeetModelsProfiles__container .MuiTab-wrapper {
  align-items: center !important;
}

.viewFeetModelsProfiles__container .MuiTab-labelIcon {
  padding-top: 6px !important;
}

.viewFeetModelsProfiles__container .MuiTab-labelIcon .MuiTab-wrapper {
  flex-direction: row-reverse !important;
  color: #ec636c !important;
  align-items: center !important;
}

#all-tab-0,
#all-tab-1 {
  align-items: center !important;
}

.subscribe-button .coralStar {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%)
    contrast(104%) !important;
}

.coralStar {
  width: 30px;
  margin-left: 5px;
  filter: invert(41%) sepia(90%) saturate(631%) hue-rotate(313deg)
    brightness(108%) contrast(85%);
}

.profile-wall-tabs button {
  opacity: 1 !important;
  font-size: 16px !important;
  font-weight: 900 !important;
}

.profile-wall-tabs button span {
  font-size: 16px !important;
}

.tab-choice-feet-models > .MuiPaper-root {
  margin-top: 0 !important;
}

.button-sticky {
  position: sticky;
  top: 50px;
  z-index: 1200;
  background: #f7f7f7 !important;
}

.profile-wall-tabs .MuiTabs-indicator {
  bottom: 15px;
}
