.subscriptionLover__content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.subscriptionLover__card {
  border: none !important;
  border-radius: 7px !important;
  margin: 16px;
  box-shadow: 0 30px 60px #8a959e33;
}

.subscriptionLoverCard__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subscriptionUser_details {
  display: flex;
  align-items: center;
}
.subscriptionLoverCardHeader__name {
  margin-top: 7px;
}

.subscriptionLover__prices {
  color: #fe294e !important;
  text-shadow: none;
  font-size: 16px;
  font-weight: 600 !important;
  min-width: 150px;
  text-align: right;
}

.subscriptionLoverCard__text {
  margin-top: 20px;
}
.subscriptionLoverCard__date {
  color: #323232;
  font-size: 1rem !important;
  margin-top: 15px;
  text-transform: capitalize;
}
.cancelSubscription_button_container {
  padding: 0 !important;
  margin: 0 -16px;
}
.subscriptionLoverCard__sub {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscriptionLoverCard__sub > p {
  color: #858585;
  text-shadow: none;
  font-size: 12px;
}

.subscriptionLoverCard__button.pink__button {
  width: 100%;
  border: none;
  padding: 0;
  border-radius: 5px !important;
  margin-top: 18px !important;
}

.subscriptionLoverCard__button.default__button {
  width: 100%;
  border: none;
  padding: 0;
  border-radius: 5px !important;
  margin-top: 18px !important;
  color: #858585 !important;
}

.subscriptionLoverCard__button > span {
  font-size: 16px;
  text-transform: capitalize;
}

.subscriptionLoverCard__suspended {
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}

.subscriptionLoverCard__suspended small {
  color: #fe294e !important;
}
