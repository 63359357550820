.welcome-feety-page-commence {
  padding-bottom: 50px;
}

.welcome-feety-page-commence button {
  width: auto !important;
}

.welcome-btn-text span {
  text-transform: initial !important;
}

@media screen and (max-width: 480px) {
  .welcome-feety-page-commence {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .welcome-feety-page-commence button {
    width: 100% !important;
  }
}
