.feetLoverProfile__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.feetLoverProfile__content {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
.feetLoverProfile__container {
  padding: 0 16px;
  width: calc(100% - 32px);
}

.feetLoverProfileContent__top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.feetLoverProfileContent__top > img {
  border-radius: 50%;
  width: 96px;
  height: 96px;
}

.feetLoverProfileContent__top > h2 {
  margin: 16px auto 0 auto !important;
  font-size: 2rem;
  font-weight: 500;
}

.feetLoverProfileContent__top > h3 {
  color: black !important;
  text-align: center;
  margin: 0 auto !important;
}

.feetLoverProfileContent__top > p {
  color: #636363;
  text-align: center;
}

.feetLoversProfile__button-content {
  margin: 0 auto 32px auto;
  text-align: center;
}

.feetLoverProfile__ticklesIcon {
  width: 32px;
  margin-right: 15px;
  margin-left: -18px;
}

.feetLoversProfile__button-content > button {
  text-align: center;
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.feetLoversProfile__button-content > button > span {
  text-transform: capitalize;
}
.feetLoverProfileContentBloc__numbr {
  margin-top: 8px;
}
.feetLoverProfileContent__picty {
  margin-top: 10px;
  padding: 24px;
  background: #ffffff;
  border-radius: 15px 15px 0 0;
  text-align: center;
  box-shadow: 0px -4px 7px -1px rgb(0 0 0 / 20%),
    0px 1px 5px 0px rgb(0 0 0 / 14%), 0px 2px 3px -6px rgb(0 0 0 / 12%);
}

.feetLoverProfileContent__picty .picty__container > div {
  margin-bottom: 30px !important;
}

.feetLoverContentPictyTitle {
  font-size: 16px;
  color: #000000;
  margin-bottom: 27px;
}

.feetLoverProfile__settingIcon {
  width: 28px;
}

.feetLoverProfile__buttonsTopBloc {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  max-width: 500px;
  /* margin: 10px auto; */
}
.feetLoverProfile__buttonsTopBloc > button {
  width: 50%;
}

.normalize-text,
.normalize-text * {
  text-transform: none !important;
}
