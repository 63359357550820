.ListItem {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: inherit !important;
  height: 100%;
  display: flex;
}

.BouncingListItem {
  transition: transform 0.5s ease-out;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: inherit !important;
  height: 100%;
  /* display: flex; */
}

.Background {
  position: absolute;
  top: calc(50% - 20px);
  right: 0;
  width: 40px;
  height: 40px;
  opacity: 0;
}
.Background > img {
  width: 100%;
  height: 100%;
}
.Wrapper {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
}
