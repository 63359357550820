/* PictureCompress component */
.picture-compress__fabicon {
  position: relative !important;
  width: 40px !important;
  height: 40px !important;
  margin-top: -58px !important;
  margin-left: 94px !important;
  background-color: #e94057 !important;
  color: white !important;
}

.picture-compress__input {
  display: none !important;
}

/* ModalFilters Component */
.paper {
  position: absolute;
  width: 272px;
  height: 65vh;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 2px 2px 6px black;
  padding: 10px 17px 37px;
  outline: none !important;
}
