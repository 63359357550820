section.upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

/* .dropzone__studio {
  width: 20%;
  height: 20%;
  position: absolute;
  top: 19px;
} */

/* .dropzone__studio {
  width: 6%;
  position: absolute;
  z-index: 5000;
  left: 9vw;
  bottom: 41px;
  border: none !important;
  height: 6%;
} */

/* section.upload-container {
  position: absolute;
  width: 100%;
  height: 178vh;
  width: 175vw;
} */

button.button-file {
  position: fixed;
  z-index: 999999;
  margin-top: 7%;
  margin-left: 5%;
}
