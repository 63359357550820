.successPayment__container {
  width: 100%;
  height: 100vh;
  background: #f7f7f7;
}

.successPayment__header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999999;
  background: #f7f7f7;
  text-align: center;
  box-shadow: 0 2px 40px #0000000d;
}

.successPayment__header > h4 {
  font-size: 18px;
  color: #636363;
  margin-top: 13px;
}

.successPayment__img {
  width: 194px;
}

.slide-in-left {
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.successPayment__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 29%;
}

.successPayment__content > h4 {
  color: #f72c3f;
  font-size: 20px;
  margin-top: 7px;
}

.flip-horizontal-bottom {
  animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}

@keyframes flip-horizontal-bottom {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(-180deg);
  }
}

.successPayment__dot-collision {
  position: relative;
  right: -126px;
  top: -40px;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #f72c3f;
  color: #f72c3f;
  box-shadow: 9999px 0 0 -5px #f72c3f;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #f72c3f;
  color: #f72c3f;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #f72c3f;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #f72c3f;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #f72c3f;
  }

  30% {
    box-shadow: 9984px 0 0 2px #f72c3f;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #f72c3f;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #f72c3f;
  }

  30% {
    box-shadow: 9999px 0 0 2px #f72c3f;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #f72c3f;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #f72c3f;
  }

  30% {
    box-shadow: 10014px 0 0 2px #f72c3f;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #f72c3f;
  }
}

.text-blur-out {
  animation: text-blur-out 1.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes text-blur-out {
  0% {
    filter: blur(0.01);
  }

  100% {
    filter: blur(12px) opacity(0%);
  }
}

.text-focus-in {
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes text-focus-in {
  0% {
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    filter: blur(0px);
    opacity: 1;
  }
}
