.bankAccount__conticklesFeetModelsAccount__headertainer {
  height: 100vh;
}
.bankAccount__content {
  max-width: 500px;
  margin: 0 auto;
}

.bankAccount__content input {
  height: 26px;
  width: calc(100% - 32px) !important;
  box-shadow: 0 0 10px #00000040;
}
.bankAccount__content select {
  height: 60px;
  width: calc(100% - 5px) !important;
  box-shadow: 0 0 10px #00000040;
}
.bankAccount__content > div {
  margin-top: 8px;
  margin-bottom: 24px;
}

.bankAccount__content > label {
  color: #000000;
  font-size: 18px;
}
