.formSignin__container input[type="text"],
.formSignin__container input[type="password"],
.formSignin__container input[type="email"],
.formSignin__container input[type="number"] {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.formSignin__container {
  height: calc(100vh - 64px);
  margin: 0 auto;
  display: table !important;
}
.formSignin__paper {
  padding-top: 32px;
  display: table-cell;
  vertical-align: middle;
}
.login__password_wrap .spanInput {
  position: relative;
  display: block;
}
.login__password_wrap .spanInput input {
  padding-right: 32px;
}
.login__password_wrap svg {
  position: absolute;
  top: 18px;
  right: 8px;
  cursor: pointer;
}
.formSignin__form {
  margin-bottom: 48px;
}

.formSignin__submit {
  margin: 3px 0 2px;
}

.formSignin__container a {
  display: block;
  width: 100%;
}

.formSignin__privacy-terms > a {
  font-size: 12px !important;
  color: #fe294e;
}

.formSignin__textUnderButtons {
  margin-top: 16px;
  align-items: center;
  justify-content: center;
}
.formSignin__loginInstaButton {
  margin-top: 8px !important;
}
.formSignin__roundedHr {
  border-radius: 5px;
  width: 40%;
  height: 6px;
  background: #ff2775;
  box-shadow: none;
  border: none;
  margin-top: 42px;
}

.email-subscription-button:hover {
  background: #ff2775 !important;
  color: #fff !important;
  opacity: 0.7 !important;
}

.formSignin__createAccount {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px !important;
  width: 100%;
}

#subs-email-input {
  box-shadow: unset !important;
  border: 1px solid #ccc;
}

.language-selection {
}

.language-selection #alert-1-hdr {
  text-align: center;
}

.language-selection .alert-button-group.sc-ion-alert-md {
  justify-content: center;
}

.language-selection .alert-button-group.sc-ion-alert-md button {
  font-size: 16px;
  font-weight: 600;
}

.language-selection .alert-wrapper.sc-ion-alert-md {
  border-radius: 24px !important;
}
