.subscriptionPayment__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f7f7f7;
}

.subscriptionPayment__header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  height: 67px;
  width: 100%;
  background: #f7f7f7;
  top: 0;
  box-shadow: 0 2px 40px #0000000d;
}

.subscriptionPayment__header > svg {
  margin-left: -134px;
  margin-top: 15px;
}

.subscriptionPayment__header > h4 {
  margin-left: -68px;
  font-size: 19px;
  color: #636363;
  margin-top: 40px;
}

.subscriptionPayment__card {
  margin-top: 106px;
  background: #ffffff;
  box-shadow: 0 30px 60px #8a959e33;
  padding: 15px;
  max-height: 108px;
  border-radius: 7px;
  width: 87%;
}

.subscriptionPayment__cardTextTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333333;
  font-size: 16px;
}

.subscriptionPayment__cardDate > p {
  color: #333333;
  text-shadow: none;
  font-size: 16px;
}

.subscriptionPayment__cardDate > p:last-child {
  margin-top: -8px;
}

.subscriptionPayment__cardTextTop > b:last-child {
  margin-right: 24px;
}

.subscriptionPayment__cardImg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}

.subscriptionPayment__cardImg > img:first-child,
.subscriptionPayment__cardImg > img:last-child {
  width: 43px;
  height: 43px;
  margin-right: 15px;
}

.subscriptionPayment__cardImg > img:last-child {
  margin-left: 15px;
}

.subscriptionPayment__cardImg > img:nth-child(2) {
  width: 71px;
}

.subscriptionPayment__form {
  width: 94%;
  margin-left: 17px;
  margin-top: 13px;
}

.subscriptionPayment__form > label {
  font-size: 17px;
}

.subscriptionPayment__expireDateLabel > label:first-child {
  margin-left: -12px;
}

.subscriptionPayment__expireDateLabel > label:last-child {
  margin-left: -26px;
}

.subscriptionPayment__form > input {
  height: 28px;
  border-radius: 10px;
  box-shadow: none;
  margin-bottom: 18px;
  width: 86%;
}

.subscriptionPayment__expireDateInput {
  display: flex;
  align-items: center;
}

.subscriptionPayment__expireDateLabel {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: -113px;
}

.subscriptionPayment__expireDateLabel > label {
  font-size: 17px;
}

.subscriptionPayment__expireDateInput > input {
  height: 29px;
  box-shadow: none;
}

.subsciptionPayment__date {
  margin-right: 25px;
}

.subscriptionPayment__expireDateInput > input:last-child {
  width: 64%;
  margin-right: 28px;
}

.subscriptionPayment__checkbox > input {
  box-shadow: none;
  width: 24px;
}

.subscriptionPayment__checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.subscriptionPayment__checkbox > p {
  color: #858585;
  font-size: 12px;
  text-shadow: none;
  margin-left: 11px;
}

.subscriptionPayment__button > p {
  color: #323232;
  font-size: 15px;
  text-shadow: none;
}

.subscriptionPayment__button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.subscriptionPayment__button > button {
  width: 78%;
  margin-bottom: 16%;
  background-image: linear-gradient(
    to right,
    rgb(248, 46, 115),
    rgb(249, 80, 107)
  );
  border-radius: 26px;
  height: 47px;
}

.subscriptionPaymentButton__button > span {
  color: white;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 17px;
}
