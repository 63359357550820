.settingFeetLovers__container {
  width: 100%;
  background: #f7f7f7;
  padding-bottom: 0;
}

.settingFeetLovers__header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #f7f7f7;
  position: fixed;
  top: 0;
  width: 100%;
}
.settingFeetLovers__content {
  margin-top: 80px;
}
