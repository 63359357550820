@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
/* APPLE PRODUCTS MEDIAQUERIES LINKS */
@import url(apple-media-queries/allIphones.css);

html,
body {
  background-color: #f7f7f7;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#date .MuiInput-formControl {
  /* margin-top: 16px; */
  height: 7vh;
  display: flex;
  padding: 14px;
  font-size: 16px;
  box-shadow: 1px 1px 3px #80808091;
  border-radius: 7px;
  background-color: white;
}

.App {
  width: 100%;
  padding-bottom: 68px;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
}

.App-V3-bg {
  background-color: #e7e7e7;
}

/* GENERAL CSS */
.full-width {
  display: block;
  width: 100%;
}

a {
  text-decoration: none;
  text-decoration-line: none;
  color: black;
  text-align: center;
}

a:hover {
  text-decoration: none !important;
  text-decoration-line: none !important;
}

/*button:active {*/
/*  filter: sepia(10);*/
/*  outline: none;*/
/*}*/

/*button:focus {*/
/*  filter: sepia(10);*/
/*  outline: none;*/
/*}*/
.pagination-loader {
  display: block;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.pink__button,
.default__button,
.white__button {
  border: none;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  height: 52px;
  min-height: 48px;
  line-height: 1 !important;
  font-size: 1.1rem !important;
  border-radius: 25px !important;
  margin-top: 20px !important;
  -webkit-box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.28) !important;
  -moz-box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.28) !important;
  box-shadow: none !important;
}

.pink__button {
  color: white !important;
  background-image: linear-gradient(
    to right,
    rgb(248, 46, 115),
    rgb(249, 80, 107)
  );
}

.white__button {
  border: 1px solid !important;
  background-color: #ffffff !important;
}

.default__button {
  color: black !important;
  background-color: #ffffff !important;
}

input,
select,
textarea {
  outline: none;
}

.input-wrapper {
  position: relative;
}

.button {
  box-shadow: none !important;
}

.error-message,
.spanInput > p {
  color: red;
  margin-top: 4px;
  margin-left: 8px;
  font-size: 0.9rem;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="date"],
select,
textarea {
  background-color: #ffffff;
  border-width: inherit;
  display: flex;
  padding: 0 14px;
  height: 60px;
  font-size: 16px;
  border-radius: 7px;
  width: 100%;
  border: none;
}

input.rounded_input {
  border-radius: 25px;
}

.cursor-pointer {
  cursor: pointer;
}

.searchInput__wrapper {
  position: relative;
  width: 100%;
}

.searchInput__wrapper input {
  box-shadow: none !important;
  padding: 0 16px 0 40px !important;
  width: calc(100% - 56px) !important;
  border-radius: 25px !important;
}

#searchIcon {
  position: absolute;
  width: 24px;
  top: 16px;
  left: 12px !important;
  filter: invert(37%) sepia(0%) saturate(0%) hue-rotate(177deg) brightness(92%)
    contrast(97%);
}

.MuiPaper-root {
  margin-top: 32px !important;
}

.makeStyles-root-2,
.MuiTab-wrapper {
  text-align: center !important;
  justify-content: start !important;
  align-items: flex-start !important;
}

.MuiTab-wrapper {
  width: unset !important;
}

.react-responsive-modal-closeButton {
  display: none !important;
}

.appBarHome {
  position: fixed !important;
  top: 0 !important;
}

.appBarHome:hover {
  opacity: inherit !important;
}

.MuiButton-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.active {
  filter: sepia(10);
}

sub {
  color: #646464 !important;
}

.MuiBottomNavigation-root {
  background-color: inherit !important;
  box-shadow: 0 -5px 19px rgb(90 86 86 / 43%);
  justify-content: space-around !important;
}

.img-profile-pict.blur {
  filter: blur(17px);
}

.even__spacing {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.display-table {
  display: table;
}

.cell-vertical-center {
  display: table-cell;
  vertical-align: middle;
}

.even__spacing > .back__button {
  display: block !important;
  position: unset !important;
}

.page_fixed_header,
.header-fixed {
  position: fixed !important;
  background-color: #ffffff;
  top: 0 !important;
  width: calc(100% - 64px) !important;
  z-index: 9999;
}

.page_fixed_header {
  left: 0;
  text-align: center;
  padding: 16px 32px;
  background-color: #ffffff;
  /* box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important; */
}

.page_fixed_header .logo {
  display: block;
  width: 80px;
}

.page_fixed_header h4 {
  line-height: 1;
  margin: 0;
}

.page_fixed_header .back__button,
.page_fixed_header .header__rightButtton {
  position: absolute;
  min-width: auto;
  top: calc(50% - 22px);
}

.page_fixed_header .header__rightButtton .MuiIconButton-label {
  position: relative;
}

.page_fixed_header .back__button {
  padding: 0;
  left: 16px;
}

.page_fixed_header .back__button svg {
  height: 40px;
  width: 40px;
}

.page_fixed_header .header__rightButtton {
  height: 42px;
  right: 16px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content__withHeader {
  padding-top: 82px !important;
  min-height: calc(100vh - 182px);
}

h4 {
  font-size: 18px !important;
  color: black !important;
}

.auth__imgLogo {
  width: 87px;
  display: block;
  margin: 0 auto 30px auto;
}

.firstColumn > b {
  font-size: 16px;
}

.firstColumn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondColumn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondColumn > b {
  font-size: 13px;
  color: black;
}

.alreadyInstalled {
  font-size: 13px;
  text-align: center;
}

.user_list {
  position: relative;
  width: 100%;
  display: grid;
  flex-wrap: nowrap;
  align-items: center;
}

.user_list > div.user_thumbnail_details {
  width: calc(25% - 16px);
  margin: 16px;
  flex: 1 1 auto;
  min-width: 200px;
  max-width: 250px;
  margin: 16px 8px;
}

.user_thumbnail_details {
  width: 100%;
}

.user_thumbnail_details > * {
  display: inline-block;
  vertical-align: middle;
}

.user_thumbnail_details .avatar {
  width: 60px;
  height: 60px;
  margin-right: 8px;
  position: relative;
}

.user_thumbnail_details .user_details {
  width: calc(100% - 70px);
}

.user_thumbnail_details .avatar .MuiAvatar-root,
.user_thumbnail_details .avatar > img {
  /* border: 1px solid #fe294e; */
  width: 100%;
  height: 100%;
}

.user_thumbnail_details h2 {
  color: #000000;
  line-height: 1;
  font-weight: 400;
  font-size: 1.2rem;
  margin: 0;
  cursor: pointer;
}

.user_thumbnail_details p {
  color: #646464;
  font-size: 1rem;
  text-transform: lowercase;
  margin: 0;
}

.minmax__wrapper,
.midmax__wrapper {
  width: calc(100% - 32px);
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.midmax__wrapper {
  max-width: 1200px;
}

.minmax__wrapper {
  max-width: 960px;
}

/* FORMS */
.birthday {
  width: 99%;
  padding: 15px;
  box-shadow: 1px 1px 3px #80808091;
  border-radius: 7px;
  background-color: white;
  margin-left: 8px;
}

.errors {
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  box-shadow: 2px 3px 6px gray;
  border: 1px solid red;
  border-radius: 4px;
}

.form-container {
  width: 100%;
}

.name-input {
  color: black;
  font-size: 16px;
  margin-top: 23px;
  margin-left: 7px;
  margin-bottom: 1px;
  font-weight: bold;
}

/*FEEDS*/
.feet_grid_list {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  justify-content: center;
  padding: 60px 16px 32px 16px;
  width: calc(100% - 32px);
}

.post-item {
  position: relative;
  max-width: 494px;
  min-width: 320px;
  width: calc(25% - 32px);
  margin: 16px;
  flex: 1 1 auto;
}

.post-user {
  position: relative !important;
}

.publiContent {
  display: table;
}

.post-content-page {
  display: table-cell;
  vertical-align: middle;
}

.post-content {
  max-width: 650px;
  margin: 0 auto;
  position: relative;
}

.post-header-menu {
  border: none !important;
}

.post-user-avatar {
  position: relative;
  width: 38px;
  height: 38px;
}

.post-user-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* border: 1px solid #FE294E; */
  cursor: pointer;
}

.post-header-text {
  width: auto;
  text-align: left;
  /* margin-left: -183px; */
}

.post-picture {
  position: relative;
  background-color: #dddddd;

  display: flex;
  justify-content: center;
  align-items: center;
}

.post-picture,
.post-picture .video-container {
  /* min-height: 320px; */
}

.post-picture .video-container video {
  max-height: 320px;
}

.post-picture > span,
.post-picture img {
  width: 100% !important;
  height: 100% !important;
  display: block !important;
}

.post-user-details {
  display: flex;
  align-items: center;
}

.post-header-text > sub {
  color: rgba(100, 100, 100, 1);
  font-size: 12px;
  margin-top: 10px;
  margin-left: -1px;
}

.post-header-text p {
  color: #000000;
  text-shadow: none;
  font-size: 15px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: -16px;
  position: relative;
  z-index: 2;
}

.post-username {
  position: relative;
  width: fit-content;
}

.post-header-check {
  height: 18px;
  border: none !important;
  position: absolute;
  top: -2px;
  right: -20px;
  z-index: 1;
}

.post-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
}

.post-offline > span {
  display: none;
}

.post-bottom {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.post-bottom .action__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;
  font-size: 12px;
  color: #323232;
}

.post-comments {
  font-size: 14px;
  color: #858585;
  margin-bottom: 6px;
}

.post-comments sub {
  cursor: pointer;
}

.post-bottom .action__buttons button {
  text-transform: capitalize;
  padding: 6px;
}

.post-bottom .action__buttons button > .MuiButton-label > img {
  margin-right: 6px;
  width: 24px;
}

.homePageModels__container .post-bottom .action__buttons {
  justify-content: start;
  gap: 24px;
}

.post-bottomContent,
.post-hr {
  position: relative;
  width: 90%;
  margin: 0 5%;
}

.post-hr {
  color: rgba(133, 133, 133, 0.1);
  opacity: 0.1;
  position: absolute;
  bottom: 0;
}

.post-bottomText {
  text-align: left;
  color: #323232;
}

.post-bottomText > p {
  color: #000000;
  text-shadow: none;
  font-size: 16px;
  font-weight: 200;
  margin: 0.3em 0 1em 0;
}

/*PROFILE*/
.profile_counts {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.profile_counts h4 {
  font-size: 1.5rem !important;
  margin: 0 !important;
}

.profile_counts p {
  position: relative;
  color: #646464 !important;
  font-size: 1rem !important;
}

.profile_counts .MuiBadge-dot {
  top: unset;
  bottom: -4px;
  right: 50%;
  z-index: 1;
  left: unset;
}

.profile__username {
  display: flex;
  margin-bottom: 5px;
}

.preferredListTitle {
  margin-bottom: 16px;
}

.picty__container {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: flex-start;
  /* justify-content: space-between; */
  text-align: center;
}

.picty__with_delete {
  width: max-content;
  margin: 0 auto;
  position: relative;
}

.picty__with_delete .postPublicationPictylist__button {
  left: -12px;
  top: -4px;
}

.picty__container > div {
  min-width: 120px;
  margin: 8px;
  position: relative;
  /* flex:1; */
  cursor: pointer;
  display: grid;
  justify-content: center;
}

.post-bottomContent .picty__container > div {
  min-width: 50px;
}

.picty__container .MuiAvatar-circle {
  margin: 0 auto;
}

.picty__container p {
  font-size: 0.9rem;
  color: #858585;
}

/*Settings*/
.setting_item {
  position: relative;
  max-width: 540px;
  margin: 0 auto;
  padding: 0 16px;
  cursor: pointer;
}

.setting_item > p,
.setting_item > div.LogoutModalContainer {
  color: #323232;
  text-shadow: none;
  font-size: 16px;
}

.setting_item > div.LogoutModalContainer {
  margin: 16px 0;
}

.languageSettings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 93%;
}

.onoffswitch {
  position: absolute;
  right: 0 !important;
  top: 0;
  width: 60px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 22px;
  padding: 0;
  line-height: 36px;
  border-radius: 36px;
  margin-right: 0;
  background-color: #edeeef;
  transition: background-color 0.3s ease-in;
  width: 80%;
}

.onoffswitch-label:before {
  content: "";
  display: block;
  width: 18px;
  margin: 0px;
  background: #636363;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 36px;
  border: 2px solid #e3e3e3;
  border-radius: 36px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: #f8d8de;
}

.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  right: 14px;
  background: #f8294f;
}

/* Blocked Account Page */
.unblock__Button.white__button {
  margin-top: 0 !important;
  padding: 8px 16px !important;
  min-height: 32px;
  height: 40px;
  font-size: 14px !important;
}

/* Blocked Account Messages */
.block_user_message {
  margin: 8px auto 16px auto;
}

.block_user_message p {
  color: #777777;
  font-size: 13px;
  line-height: 1.2;
  padding-right: 8px;
}

.formik-errors {
  color: red;
}

.blocked_account_list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* GLOBAL MEDIA QUERIES LINKS */

/*@import url(media-queries/for-320px.css);*/
/*@import url(media-queries/for-360px.css);*/
/*@import url(media-queries/for-375px.css);*/
/*@import url(media-queries/for-384px.css);*/
/*@import url(media-queries/for-412px.css);*/
/*@import url(media-queries/for-540px.css);*/
/*@import url(media-queries/for-768px.css);*/
/*@import url(media-queries/for-1024px.css);*/
/*@import url(media-queries/min-1440px.css);*/

@media only screen and (max-width: 480) {
  .createMessage__header.header.page_fixed_header {
    position: fixed !important;
    background-color: #ffffff;
    top: 0 !important;
    width: 100% !important;
    z-index: 999;
  }
}
