.messages__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  width: 100%;
  position: relative;
  z-index: 9;
  cursor: pointer;
}
.hidden {
  display: none;
}

.messages__CardNoReaded {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  width: 100%;
  position: relative;
  z-index: 9;
  /* box-shadow: 0 3px 6px #00000029; */
  /* border-radius: 10px; */
  /* background-color: #ffffff; */
  margin: 8px 0;
}
.messageUser_details {
  display: flex;
  align-items: center;
  gap: 10px;
  width: calc(100% - 120px);
}
.messageUser_details .user_thumbnail_details {
  width: 100%;
  min-width: auto;
}

.messagesCardNoRead__text > p,
.messagesCardNoRead__text > h4 {
  font-weight: bold;
}

.messagesCard__text > p,
.messagesCard__text > h4 {
  font-weight: 500;
}

.messagesCardNoRead__text {
  color: #000000 !important;
  font-weight: 600;
}

.user_details > .messagesCardNoRead__text {
  color: #000000 !important;
  font-weight: 600;
}

.user_details > .message__subscriber {
  color: #ff2775 !important;
}

.message__favIcon {
  height: 24px;
}

.MuiAvatar-root.MuiAvatar-circle.messages__picture {
  border: 1px solid #fe294e;
  width: 50px;
  height: 50px;
}

.messageCard__avatar {
  position: relative;
  margin-left: 10px;
}

.messages__online {
  width: 13px;
  height: 13px;
  background: #29fe8c;
  border-radius: 50%;
  position: absolute;
  left: -2px;
  top: -3px;
  border: 1px solid #ffffff;
}

.messages__offline {
  width: 13px;
  height: 13px;
  background: #fe294e;
  border-radius: 50%;
  position: absolute;
  margin-left: 24.8%;
  margin-top: 36px;
  border: 1px solid #ffffff;
}

.messages__name {
  font-size: 16px;
  color: #323232;
  text-shadow: none;
}

.messages__message {
  color: #646464;
  text-shadow: none;
  font-size: 11px;
  margin: 0;
  text-align: center;
  position: relative;
  z-index: 99999999;
}

.messagesCard__text {
  margin-left: -31px;
}

.messagesCard__text img.heart_allMessages {
  margin-top: 2px;
}

.messages__Date {
  position: absolute;
  top: 26px;
  margin: 0;
  right: 10px;
  font-size: 12px;
  text-shadow: none;
  color: #858585;
}

.messageSelected__favIcon {
  height: 24px;
  margin-right: -16px;
}

.messagesModels__ticklesIcon {
  width: 20px;
  /*position: absolute;*/
  /*right: 26px;*/
  margin-top: 20px;
}

.messagesModels__customMediaIcon {
  width: 22px;
  /*position: absolute;*/
  /*right: 26px;*/
  margin-top: 20px;
}
