.buyTickleContainer {
  width: 100%;
  height: auto;
  position: absolute;
  background: #f7f7f7;
}

.buyTicklesCredit__header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #f7f7f7;
}

.buyTicklesCredit__top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(
    to right,
    rgb(248, 46, 115),
    rgb(249, 80, 107)
  );
  border-radius: 14px;
  margin-left: 16px;
  margin-right: 16px;
}

.buyTicklesCredit__header > svg {
  margin-left: -89px;
}

.buyTicklesCredit__header > h4 {
  color: #636363;
  margin-left: -40px;
}

.buyTicklesCredit__top > img {
  width: 51px;
}

.buyTicklesCredit__top > p {
  color: #f7f7f7;
  text-shadow: none;
  font-size: 18px;
  padding-top: 18px;
}

.buyTicklesCredit__top > b {
  color: #f7f7f7;
  margin-top: 20px;
  margin-bottom: 28px;
  font-size: 22px;
}

.buyTicklesCredit__cards {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  flex-wrap: wrap;
}
.buyTicklesCredit__cards > * {
  padding: 4px !important;
}
.buyTicklesCredit__card {
  background: #ffffff;
  border: 1px solid #f72c3f;
  width: 94px;
  height: 94px;
  border-radius: 10px;
  box-shadow: 0 0 10px #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.buyTicklesCredit__card > h4 {
  margin-top: 9px;
  font-size: 22px;
}

.buyTicklesCredit__card > p {
  color: #636363;
  font-size: 13px;
  text-shadow: none;
  margin-top: -30px;
}

.buyTicklesCredit__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 48px;
  margin-bottom: 50px;
}
.buyTicklesCredit__buttons > button {
  width: 50%;
}
.buyTicklesCredit__card:active {
  filter: sepia(1);
}

.activeTickles {
  filter: sepia(1);
  background: #e0e0e0;
  box-shadow: inset 20px 20px 60px #bebebe, inset -20px -20px 60px #ffffff;
}
