.modalSocial__paper {
  position: absolute;
  width: 268px;
  height: 302px;
  background-color: white;
  /* box-shadow: 2px 2px 6px black; */
  padding: 10px 17px 37px;
}

.modal-social {
  border-radius: 7px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
}

.modal-social:focus {
  outline: none !important;
}

.socialLoginWelcome__content {
  width: 112.6%;
  margin-left: -17px;
  margin-top: -25px;
}

.socialLoginWelcome__header {
  background-image: linear-gradient(
    to right,
    rgba(255, 39, 117, 1),
    rgba(255, 87, 104, 1)
  );
  border-radius: 7px 7px 0 0;
  height: 68px;
  width: 106.7%;
  margin-top: -26px;
}

.socialLoginWelcome__header > h2 {
  color: white;
  font-family: "Roboto";
  font-size: 18px;
  padding-top: 8%;
}

.socialLoginWelcome__body {
  width: 69%;
  align-items: center;
  justify-content: center;
  width: 68%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 49px;
}

.socialLoginWelcome__top > p,
.socialLoginWelcome__center > p,
.socialLoginWelcome__bottom > p {
  color: #323232 !important;
  text-shadow: inherit;
}

.socialLoginWelcome__bottom > h3 {
  font-weight: 600;
  font-size: 16px;
  color: #ff2775;
  margin-top: 33px;
  margin-bottom: -12px;
}

.socialLoginWelcome__bottom {
  width: 134%;
  margin-left: 0;
  background: #f7f7f7;
  padding: 5px;
  border-radius: 10px;
  height: 72px;
}

.socialLoginWelcome__center-button {
  color: #ff2775 !important;
  text-transform: capitalize !important;
  margin-bottom: -36px !important;
}

.socialLoginWelcome__center {
  margin-top: 0;
  background: #f7f7f7;
  padding: 5px;
  width: 134%;
  border-radius: 10px;
  margin-bottom: 16px;
  margin-left: 9px;
  height: 72px;
}

.socialLoginWelcome__center-p {
  margin-top: -12px;
}
