.identify-myself-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  text-align: center;
}

.identify-myself-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
