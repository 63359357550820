.postPublication__container {
  width: 100%;
  height: 100vh;
}

.postPublication__container .content__withHeader {
  position: relative;
}

.postPublication__header > button:last-child {
  color: #f72c3f !important;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 20px;
}

.postPublication__content {
  position: fixed;
  margin-left: -16px;
  width: 93%;
  max-width: 960px;
  background: #f7f7f7;
  box-shadow: 0 6px 6px #8a959e33;
  border-radius: 0 0 7px 7px;
  z-index: 2;
  padding: 16px;
  top: 50px;
}

.postPublicationContent__top {
  display: flex;
  align-items: center;
  gap: 10px;
}

.postPublicationContentTop__img {
  min-width: 70px;
  max-width: 70px;
  height: 118px;
  background-color: #ededed;
  border-radius: 5px;
  position: relative;
}

.postPublicationContentTop__img > video {
  margin: 0 !important;
}

.postPublicationContentTop__img > img {
  object-fit: cover;
}

.postDetailSettings {
  width: 100%;
}

.onoffswitch.postPublicationContentTop__privatePublication {
  right: unset !important;
  top: unset;
  position: relative;
  margin-left: 16px;
}

.postPublication__textArea {
  outline: none;
  border: none;
  color: black;
  margin-top: 0px;
  padding: 16px;
  width: calc(100% - 32px);
  /*background: #f7f7f7;*/
  font-family: "Roboto";
}

.postPublication__textArea::placeholder {
  color: #323232;
  font-size: 16px;
}

.postPublicationcontent__selectedPictyText {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.postPublicationcontent__selectedPictyText > b {
  font-size: 17px;
  color: #000000;
}

.postPublicationcontent__selectedPictyText > p {
  color: #323232;
  font-size: 16px;
  text-shadow: none;
  margin-left: 5px;
}

.postPublicationSelectedPicty__list {
  position: relative;
}

.postPublicationPictiesChoiceFavorites__list.picty__container {
  width: calc(100% - 16px);
}

.postPublicationPictiesChoiceFavorites__list.picty__container > div,
.postPublicationSelectedPicty__list.picty__container > div {
  width: calc(100% / 6) !important;
  margin: 0;
}

.postPublicationPictiesChoiceFavorites__list.picty__container > div p,
.postPublicationSelectedPicty__list.picty__container > div p {
  font-size: 0.7rem;
  white-space: normal;
}

.postPublication__selectedAvatar {
  border: 2px solid #e94057;
}

.postPublicationPictylist__button {
  cursor: pointer;
  position: absolute;
  width: 14px;
  height: 14px;
  z-index: 999999;
  left: 35%;
  top: 0;
}

.postPublicaionPictyList__names {
  position: relative;
  margin-top: -33px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #858585;
  opacity: 0.9;
  padding-bottom: 31px;
}

.postPublicationPictiesChoice__favorites {
  margin-left: 0px;
  margin-top: 300px;
}

.postPublicationPictiesChoice__favorites > b {
  margin-bottom: 8px;
  font-size: 18px;
}

.postPublicationPictiesChoiceFavorites__list {
  box-shadow: 0 18px 30px #8a959e33;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 8px;
}

.postPublication__favoritesAvatar > img {
  width: 42px !important;
  height: 42px !important;
}

.postPublication__selectedAvatar > img {
  width: 42px !important;
  height: 42px !important;
}

.postPublicaionPictyListFavorites__names {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  color: #858585;
  opacity: 0.9;
  padding-bottom: 31px;
}

.postPublicationContentTop__privatePublicationContent {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.postPublicationContentTop__privatePublicationContent > p {
  font-weight: 600;
  margin: 0;
}

.postPublicationContentTop__privatePublicationContent
  > .advicePrivatePublication {
  margin-left: auto;
  color: #f50057;
}

.postPublication__withWarningText {
  display: flex;
  gap: 10px;
}

.postPublication__withWarningText .dummy-element {
  min-width: 72px;
}

.postPublication__withWarningText .warning-text,
.postPublication__withWarningText .warning-text a {
  font-size: 12px;
  color: #f50057;
}

.postPublication__withWarningText .warning-text a {
  top: 5px !important;
  position: relative;
  text-decoration: underline;
}
