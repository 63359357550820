.pictureCropper__img-container {
  width: 335px;
  height: 480px;
  float: left;
}

.pictureCropper__img-preview {
  width: 90px;
  height: 90px;
  float: left;
  margin-left: -18vw;
  /* margin-top: -498px; */
  position: fixed;
  box-shadow: 1px 2px 10px black;
  border: 1px solid blue;
  border-radius: 50%;
}

.pictureCropper__btn-imageEditor {
  background: #14847b !important;
}

.cropper-container .cropper-bg {
  width: inherit !important;
}

.react-responsive-modal-modal {
  height: 532px;
}

@media (max-width: 360px) {
  .cropper-container {
    width: 280px !important;
  }

  .react-responsive-modal-container {
    width: 340px !important;
  }

  .react-responsive-modal-modal {
    width: 280px !important;
    margin-top: 10vh;
  }

  .pictureCropper__img-container {
    width: 280px !important;
  }

  .cropper-canvas {
    width: 280px !important;
  }
}

@media (min-width: 361px) and (max-width: 412px) {
  .cropper-container {
    width: 300px !important;
    height: 458px !important;
  }

  .react-responsive-modal-container {
    width: 360px !important;
    margin-top: 4px !important;
  }

  .react-responsive-modal-modal {
    width: 300px !important;
    margin-top: 10vh;
  }

  .pictureCropper__img-container {
    width: 300px !important;
  }

  .cropper-canvas {
    width: 300px !important;
    margin-top: -11px;
    height: 458px !important;
  }
}

@media (min-width: 415px) and (max-width: 768px) {
  .cropper-container {
    width: 420px !important;
  }

  .react-responsive-modal-container {
    width: 480px !important;
    margin-left: -2%;
    margin-top: 23%;
  }

  .react-responsive-modal-modal {
    width: 420px !important;
  }

  .pictureCropper__img-container {
    width: 420px !important;
  }

  .cropper-canvas {
    width: 420px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .cropper-container {
    width: 620px !important;
  }

  .react-responsive-modal-container {
    width: 680px !important;
    margin-left: 17%;
    margin-top: 23%;
  }

  .react-responsive-modal-modal {
    width: 620px !important;
  }

  .pictureCropper__img-container {
    width: 620px !important;
  }

  .cropper-canvas {
    width: 620px !important;
  }
}
